<div class="login-main">

  <div class="content" *ngIf="isLoginPage">
    <emp-logo routerLink="../"></emp-logo>

    <div class="login-container-two">
      <form #loginForm="ngForm">
            <span class="p-float-label p-input-icon-left">
                <i class="pi pi-user"></i>
                <input id="float-input" type="text" pInputText name="email" ngModel>
                <label for="float-input">Email</label>
            </span>
        <span class="p-float-label p-input-icon-left">
                <i class="pi pi-lock"></i>
                <input id="float-input" type="password" pInputText name="password" ngModel
                       (keyup.enter)="clickLogin(loginForm.value)">
                <label for="float-input">Password</label>
            </span>
        <!--      <span class="p-float-label p-input-icon-left">-->
        <!--        <p-dropdown [options]="profiles" ngModel name="selectedProfile" optionLabel="name"></p-dropdown>-->
        <!--      </span>-->
        <div class="container-3">
<!--                <span class="span-1">-->
<!--                    <label><a href="/reset-password">Forgot password?</a></label>-->
<!--                </span><br>-->
          <button pButton pRipple class="p-button-secondary" type="button" label="Login"
                  (click)="clickLogin(loginForm.value)"></button>
          <br>
          <span class="span-1">
                    <label>Don't have an account? <a (click)="signUp()">Sign up now</a></label>
                </span>
        </div>
      </form>
    </div>
  </div>

  <div class="content" *ngIf="!isLoginPage">
    <emp-logo></emp-logo>

    <div class="login-container-two">
      <form [formGroup]="signUpForm">
            <span class="p-float-label p-input-icon-left">
                <i class="pi pi-user"></i>
                <input id="float-input" type="text" pInputText formControlName="firstName">
                <label for="float-input">First Name</label>
            </span>
        <span class="p-float-label p-input-icon-left">
                <i class="pi pi-user"></i>
                <input id="float-input" type="text" pInputText formControlName="lastName">
                <label for="float-input">Last Name</label>
            </span>
        <span class="p-float-label p-input-icon-left">
                <i class="pi pi-user"></i>
                <input id="float-input" type="text" pInputText formControlName="email">
                <label for="float-input">Email</label>
            </span>
        <span class="p-float-label p-input-icon-left">
          <p-password [toggleMask]="true" formControlName="password" placeholder="Password"></p-password>

            </span>

        <nav class="p-float-label p-input-icon-left role">
          <div class="p-field-radiobutton">
            <p-radioButton class="radiobutton" formControlName="role" value="organizer" id="city1"></p-radioButton>
            <label for="city1">Organization</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton class="radiobutton" formControlName="role" value="participant" id="city2"></p-radioButton>
            <label for="city2">Participant</label>
          </div>
        </nav>
        <div class="container-3">
          <br>
          <button pButton pRipple class="p-button-secondary" type="button" label="Sign Up" (click)="createAccount()"
          ></button>
          <br>
          <span class="span-1">
                    <label>Do you have an account? <a (click)="signUp()">Login here</a></label>
                </span>
        </div>
      </form>
    </div>
  </div>
</div>
