<div class="approval-main">

    <div class="header-section">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Search Inquiry" />
        </span>
        
        <button routerLink="../inquiries-createnew" pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="New Inquiry"></button>
    </div>

    <p-table [value]="inquires" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10,25,50]">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 15%;">
                    INQ No
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>
                <th>
                    Description
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>
                <th>
                    Date
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>
                <th style="width: 15%;">
                    Status
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>
                <th style="width: 10%;">More</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-inquire>
            <tr>
                <td>{{inquire.id}}</td>
                <td>{{inquire.description}}</td>
                <td>{{inquire.date}}<br><span class="l-2">{{inquire.time}}</span></td>
                <td><span [class]="'status status-' + inquire.status">{{inquire.status}}</span></td>
                <td><span (click)="show()" class="more-span pi pi-ellipsis-v"></span></td>
            </tr>
        </ng-template>
    </p-table>
</div>
