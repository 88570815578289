<div class="login-main">

  <emp-logo></emp-logo>

  <div class="login-container-two">
    <form #resetForm="ngForm">
            <span class="p-float-label p-input-icon-left">
                <i class="pi pi-envelope"></i>
                <input id="float-input" type="email" pInputText name="email" ngModel>
                <label for="float-input">Enter your email</label>
            </span>

      <div class="container-3">
        <button pButton pRipple class="p-button-secondary" type="button" label="Reset Password"
                (click)="clickReset(resetForm.value)"></button><br>
        <span>
                        <label><a href="/login">Back</a></label>
                    </span>
      </div>
    </form>
  </div>
</div>
