import { Component, OnInit } from '@angular/core';
import {DataServiceService} from '../../common/services/data-service.service';

@Component({
  selector: 'app-website-header',
  templateUrl: './website-header.component.html',
  styleUrls: ['./website-header.component.scss']
})
export class WebsiteHeaderComponent implements OnInit {

  constructor(
    public dataService: DataServiceService
  ) { }

  ngOnInit(): void {
  }

}
