import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-organizers-createnew',
  templateUrl: './admin-organizers-createnew.component.html',
  styleUrls: ['./admin-organizers-createnew.component.scss']
})
export class AdminOrganizersCreatenewComponent implements OnInit {

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
