<div class="event-main">

    <div class="header">
          <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" pInputText placeholder="Search Event" />
          </span>
          <!-- <button pButton pRipple type="button" (click)="btnClick();" class="p-button-outlined p-button-secondary" label="Create New"></button> -->
      </div>

    <p-table [value]="events" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             [rowsPerPageOptions]="[10,25,50]">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 15%;">
            Event No
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th>
            Event Name
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th>
            Description
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th>
            Event Date
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th>
            Status
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th style="width: 10%;"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-event>
        <tr>
          <td>{{event.id}}</td>
          <td title="{{event.eventName}}">{{event.eventName | slice:0:20}}</td>
          <td title="{{event.description}}">{{event.description | slice:0:20}}...</td>
          <td>{{event.date}}<br><span class="l-2">{{event.time}}</span></td>
          <td><span [class]="'status status-' + event.status">{{event.status}}</span></td>
          <td title="Event details" class="view-event" (click)="viewEvent(event.event)" ><i class="pi pi-book"></i></td>
        </tr>
      </ng-template>
    </p-table>
  </div>


  <!-- <p-menu #menu [popup]="true" [model]="moreitems"></p-menu> -->
