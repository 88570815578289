import { Component, OnInit } from '@angular/core';
import {OrganizerEventService} from '../organizer-event/organizer-event.service';
import {DataServiceService} from '../../common/services/data-service.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-organizer-main',
  templateUrl: './organizer-main.component.html',
  styleUrls: ['./organizer-main.component.scss']
})
export class OrganizerMainComponent implements OnInit {

  constructor(
    public organizerEventService: OrganizerEventService,
    private dataService: DataServiceService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getEventsRelatedToOrganizer();
  }

  getEventsRelatedToOrganizer(){
    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT_SEARCH;
    const orgId = sessionStorage.getItem('orgId');
    const searchCriteria = {organizerId: orgId};
    const isLoadBefore = sessionStorage.getItem('eventsForOrganizer');
    if (!isLoadBefore && orgId) {
      this.http.post(path, searchCriteria).toPromise().then(re => {
        this.organizerEventService.eventsRelatedToLoggedUser = JSON.parse(JSON.stringify(re)).data;
        sessionStorage.setItem('eventsForOrganizer', JSON.stringify(this.organizerEventService.eventsRelatedToLoggedUser));
      }).catch(e => {
        console.log(e);
        // ToDo: Add Notification
      });
    }
  }

}
