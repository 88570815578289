import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {OrganizerEventDetailsComponent} from './organizer-event-details/organizer-event-details.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import jwt_decode from 'jwt-decode';
import {OrganizerEventService} from './organizer-event.service';

export interface Event {
  id: string;
  eventName: string;
  description: string;
  date: string;
  time: string;
  status: string;
}

@Component({
  selector: 'app-organizer-event',
  templateUrl: './organizer-event.component.html',
  styleUrls: ['./organizer-event.component.scss'],
  providers: [DialogService, DynamicDialogRef]
})
export class OrganizerEventComponent implements OnInit {

  moreitems: MenuItem[] = [];
  first = 0;
  rows = 10;
  orgId: any = '';
  loggedUserToken: any;

  events: any = [
    // {'id': '0', 'eventName': 'Google IO', 'description': 'ABC Event', 'date': '02.02.2021', 'time': '6.30pm', 'status': 'reject'},
    // {'id': '1', 'eventName': 'IEEE Meetup', 'description': 'John Event', 'date': '02.02.2021', 'time': '6.30pm', 'status': 'active'},
    // {'id': '2', 'eventName': 'IEEE Meetup', 'description': 'Albert Event', 'date': '02.02.2021', 'time': '6.30pm', 'status': 'pending'}
  ];

  constructor(
    private router: Router,
    public dialogRef: DynamicDialogRef,
    public dialogService: DialogService,
    private dataService: DataServiceService,
    private http: HttpClient,
    public organizerEventService: OrganizerEventService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    const decoded: any = jwt_decode(this.loggedUserToken);
    const orgId = sessionStorage.getItem('orgId');

    if (!orgId) {
      this.loadOrganizerId(decoded);
    }
    this.loadEventsData();

    this.moreitems = [
      {
        label: 'Applications',
        routerLink: '/d/organizer/organizer-event-applications'
      },
      {
        label: 'More Details',
        command: () => {
          this.show();
        }
      },
    ];
  }

  moreClick(event: any) {
    this.organizerEventService.currentEventMore = event;
  }

  loadOrganizerId = (user: any) => {
    const path = this.dataService.BASE_PATH + this.dataService.ORGANIZER_PROFILE_SEARCH;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    const searchCriteria = {email: user.data.email};
    this.http.post(path, searchCriteria, header).toPromise().then(res => {
      const result = JSON.parse(JSON.stringify(res));
      sessionStorage.setItem('orgId', result.data[0]._id);
    });
  };

  loadEventsData() {
    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT_SEARCH;
    const orgId = sessionStorage.getItem('orgId');
    const searchCriteria = {organizerId: orgId};
    const isLoadBefore = sessionStorage.getItem('eventsForOrganizer');
    if (!isLoadBefore && orgId) {
      this.http.post(path, searchCriteria).toPromise().then(re => {
        this.events = JSON.parse(JSON.stringify(re)).data;
        sessionStorage.setItem('eventsForOrganizer', JSON.stringify(this.events));
      }).catch(e => {
        console.log(e);
        // ToDo: Add Notification
      });
    }
    if (isLoadBefore) {
      this.events = JSON.parse(isLoadBefore);
    }
  }

  show() {
    this.router.navigateByUrl('/d/organizer/organizer-event/event-organizer-details');
    this.dialogRef = this.dialogService.open(OrganizerEventDetailsComponent, {
      width: '70%',
      contentStyle: {'height': 'max-content', 'overflow': 'auto'}
    });
  }

  btnClick() {
    this.router.navigateByUrl('/d/organizer/organizer-event-createnew-event');
  }

}
