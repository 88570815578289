import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-website-aboutus',
  templateUrl: './website-aboutus.component.html',
  styleUrls: ['./website-aboutus.component.scss']
})
export class WebsiteAboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
