import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrganizerEventService {

  public createNewEvent: any = [];
  public currentEventMore: any = [];
  public eventsRelatedToLoggedUser: any = [];

  constructor() {
  }
}
