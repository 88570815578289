import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-admin-organizers-search',
  templateUrl: './admin-organizers-search.component.html',
  styleUrls: ['./admin-organizers-search.component.scss']
})
export class AdminOrganizersSearchComponent implements OnInit {

  loggedUserToken: any;
  isCreateNew: boolean = true;
  organizerDetailsForm = new FormGroup({
    organizationName: new FormControl(),
    regNo: new FormControl(),
    organizationType: new FormControl(),
    organizationEmail: new FormControl(),
    organizationContactNo: new FormControl(),
    organizationAddressLineOne: new FormControl(),
    organizationAddressLineTwo: new FormControl(),
    city: new FormControl(),
    description: new FormControl()
  });

  representativeDetailsForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    nicNo: new FormControl(),
    email: new FormControl(),
    contactNo: new FormControl()
  });

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    this.createForm();
  }

  createForm() {
    this.organizerDetailsForm = this.fb.group({
      organizationName: [''],
      regNo: [''],
      organizationType: [''],
      organizationEmail: ['', [Validators.required, Validators.email]],
      organizationContactNo: [''],
      organizationAddressLineOne: [''],
      organizationAddressLineTwo: [''],
      city: [''],
      description: ['']
    });

    this.representativeDetailsForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      nicNo: [''],
      email: ['', [Validators.required, Validators.email]],
      contactNo: ['']
    });
  }

  createOrganization() {
    console.log(this.organizerDetailsForm.value);
    console.log(this.representativeDetailsForm.value);

    const path = this.dataService.BASE_PATH + this.dataService.ORGANIZER_PROFILE;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};

    const organizerProfile = {
      organizationName: this.organizerDetailsForm.value.organizationName,
      regNo: this.organizerDetailsForm.value.regNo,
      organizationType: this.organizerDetailsForm.value.organizationType.name,
      organizationEmail: this.organizerDetailsForm.value.organizationEmail,
      organizationContactNo: this.organizerDetailsForm.value.organizationContactNo,
      organizationAddressLineOne: this.organizerDetailsForm.value.organizationAddressLineOne,
      organizationAddressLineTwo: this.organizerDetailsForm.value.organizationAddressLineTwo,
      city: this.organizerDetailsForm.value.city,
      description: this.organizerDetailsForm.value.description,
      firstName: this.representativeDetailsForm.value.firstName,
      lastName: this.representativeDetailsForm.value.lastName,
      nicNo: this.representativeDetailsForm.value.nicNo,
      email: this.representativeDetailsForm.value.email,
      contactNo: this.representativeDetailsForm.value.contactNo
    };
    if (this.organizerDetailsForm.valid && this.representativeDetailsForm.valid) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to create a profile?',
        accept: () => {
          this.isCreateNew = !this.isCreateNew;
          this.http.post(path, organizerProfile, header).toPromise().then(res => {
            // ToDo: Add Notification
            console.log(res);
          }).catch(e => {
            // ToDo: Add Notification
            console.log(e);
          });
        },
        reject: () => {
          // ToDo: add something if needed to take any action when rejecting the confirmation
        }
      });
    }
  }

  searchOrganizationProfiles(value: any) {
    const path = this.dataService.BASE_PATH + this.dataService.ORGANIZER_PROFILE_SEARCH;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    const searchCriteria = {email: value};
    if (value) {
      this.http.post(path, searchCriteria, header).toPromise().then(resData => {
        // ToDo: Add Notification
        const res = JSON.parse(JSON.stringify(resData));

        this.isCreateNew = !this.isCreateNew;

        this.organizerDetailsForm = this.fb.group({
          organizationName: [res.data[0].organizationName],
          regNo: [res.data[0].regNo],
          organizationType: [res.data[0].organizationType],
          organizationEmail: [res.data[0].organizationEmail],
          organizationContactNo: [res.data[0].organizationContactNo],
          organizationAddressLineOne: [res.data[0].organizationAddressLineOne],
          organizationAddressLineTwo: [res.data[0].organizationAddressLineTwo],
          city: [res.data[0].city],
          description: [res.data[0].description]
        });

        this.representativeDetailsForm = this.fb.group({
          firstName: [res.data[0].firstName],
          lastName: [res.data[0].lastName],
          nicNo: [res.data[0].nicNo],
          email: [res.data[0].email],
          contactNo: [res.data[0].contactNo]
        });
      }).catch(e => {
        // ToDo: Add Notification
        console.log(e);
      });
    }
  }
}
