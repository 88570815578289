<div class="payment-main">

    <div class="grid-one">
        <form #form="ngForm">
            <span class="ln-1">
                <label style="display:block;margin-bottom:4px">Select Event</label>
                <p-dropdown [options]="events" name="events" ngModel placeholder="Select"
                            optionLabel="name" [showClear]="true"></p-dropdown>
            </span><br>
        </form>
        <span class="header" style="margin-top: 10px;">Payment Method</span>
        <form #form="ngForm">
<!--            <span class="ln-2">-->
<!--                <p-radioButton name="payment" value="card" [(ngModel)]="paymentType" inputId="payment1"></p-radioButton>-->
<!--                <label for="payment1"> Card Payment </label>-->
<!--            </span>-->
            <span class="ln-2">
                <p-radioButton name="payment" value="bank" [(ngModel)]="paymentType" inputId="payment2"></p-radioButton>
                <label> Bank Deposit </label>
            </span>
<!--            <span class="ln-2">-->
<!--                <p-radioButton name="payment" value="wire" [(ngModel)]="paymentType" inputId="payment3"></p-radioButton>-->
<!--                <label for="payment3"> Wire Tranfer </label>-->
<!--            </span>-->
            <span class="ln-2">
                <p-radioButton name="payment" value="online" [(ngModel)]="paymentType" inputId="payment4"></p-radioButton>
                <label> Online Transfer </label>
            </span>

        </form>

        <span class="header" style="margin-top: 10px;">Payment Details</span>
        <form #form="ngForm">
            <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Receipt/Transaction No</label>
                <input type="text" id="receiptNo" name="firstName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Amount (LKR)</label>
                <input type="text" id="amount" name="firstName" ngModel pInputText placeholder="">
            </span>

            <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Deposit/ Transfer Date</label>
                <input type="text" id="transferDate" name="firstName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Bank Name</label>
                <input type="text" id="bankName" name="firstName" ngModel pInputText placeholder="">
            </span>

            <span class="ln-1">
                <label style="display:block;margin-bottom:4px">Receipt Image</label>
                <input type="text" id="image" name="firstName" ngModel pInputText placeholder="">
            </span>
        </form>
    </div>

    <div class="grid-two">
        <form #form="ngForm">
            <span class="ln-1">
                <label style="display:block;margin-bottom:4px">Notes to RSVP.LK (if any)</label>
                <textarea type="text" id="notes" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
            <div style="position: absolute;right:0;bottom:0;">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Cancel"></button>
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Submit"></button>
            </div>

        </form>
    </div>

</div>
