<form #form="ngForm">
    <header>Register</header>

    <span class="ln-1">
        <label for="firstName">Name</label>
        <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
    </span>
    <span class="ln-1">
        <label for="lastName">Address</label>
        <input type="text" id="lastName" name="lastName" ngModel pInputText placeholder="">
    </span>
    <span class="ln-1">
        <label for="lastName">Email</label>
        <input type="email" id="lastName" name="lastName" ngModel pInputText placeholder="">
    </span>
    <span class="ln-1">
        <label for="lastName">Contact No</label>
        <input type="email" id="lastName" name="lastName" ngModel pInputText placeholder="">
    </span>

    <span style='padding-top: 20px;width: 100%;'>
        <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Register"></button>
    </span>

</form>