import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import jwt_decode from 'jwt-decode';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {

  adminProfileFrom = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    nicNo: new FormControl(),
    email: new FormControl(),
    contactNo: new FormControl(),
    addressLineOne: new FormControl(),
    addressLineTwo: new FormControl(),
    city: new FormControl(),
    description: new FormControl(),
  });

  loggedUser: any;
  loggedUserToken: any;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private dataservice: DataServiceService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    const decoded: any = jwt_decode(this.loggedUserToken);
    this.createForm(decoded);

  }

  createForm = (decoded: any) => {
    this.getProfileDetails(decoded.data.email).then(res => {
      const profileDB = JSON.parse(JSON.stringify(res)).data;
      this.adminProfileFrom = this.fb.group({
        firstName: [(profileDB?.firstName ? profileDB.firstName : ''), [Validators.required]],
        lastName: [profileDB?.lastName ? profileDB.lastName : '', [Validators.required]],
        nicNo: [profileDB?.nicNo ? profileDB.nicNo : '', [Validators.required]],
        email: [profileDB?.email ? profileDB.email : '', [Validators.required]],
        contactNo: [profileDB?.contactNo ? profileDB.contactNo : '', [Validators.required]],
        addressLineOne: [profileDB?.addressLineOne ? profileDB.addressLineOne : '', [Validators.required]],
        addressLineTwo: [profileDB?.addressLineTwo ? profileDB.addressLineTwo : '', [Validators.required]],
        city: [profileDB?.city ? profileDB.city : '', [Validators.required]],
        description: [profileDB?.description ? profileDB.description : '', [Validators.required]]
      });
    }).catch(e => {
      console.log(e);
      this.adminProfileFrom = this.fb.group({
        firstName: [(decoded?.data?.firstName ? decoded.data.firstName : ''), [Validators.required]],
        lastName: [decoded?.data?.lastName ? decoded.data.lastName : '', [Validators.required]],
        nicNo: [decoded?.data?.nicNo ? decoded.data.nicNo : '', [Validators.required]],
        email: [decoded?.data?.email ? decoded.data.email : '', [Validators.required]],
        contactNo: [decoded?.data?.contactNo ? decoded.data.contactNo : '', [Validators.required]],
        addressLineOne: [decoded?.data?.addressLineOne ? decoded?.data.addressLineOne : '', [Validators.required]],
        addressLineTwo: [decoded?.data?.addressLineTwo ? decoded?.data.addressLineTwo : '', [Validators.required]],
        city: [decoded?.data?.city ? decoded?.data.city : '', [Validators.required]],
        description: [decoded?.data?.description ? decoded.data.description : '', [Validators.required]]
      });
    });

  };

  getProfileDetails(email: any) {
    const path = this.dataservice.BASE_PATH + this.dataservice.ADMIN_PROFILE + '/' + email;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    return this.http.get(path, header).toPromise();
  }

  updateProfile = () => {
    const path = this.dataservice.BASE_PATH + this.dataservice.ADMIN_PROFILE;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};

    this.confirmationService.confirm({
      message: 'Are you sure that you want to update profile?',
      accept: () => {
        this.http.put(path, this.adminProfileFrom.value, header).toPromise().then(res => {
          // ToDo: Add Notification
          console.log(res);
        }).catch(e => {
          // ToDo: Add Notification
          console.log(e);
        });
      },
      reject: () => {
      // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  };
}

