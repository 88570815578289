<div class="container">
    <div class="grid-one">
    </div>

    <div class="grid-two">
        <span class="header">Personal Details</span>

        <form [formGroup]="adminProfileFrom">
                <span class="ln-2">
                    <label for="firstName" >First Name</label>
                    <input type="text" id="firstName" formControlName="firstName" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
                </span>
                <span class="ln-2">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" formControlName="lastName" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
                </span>
              <span class="ln-3">
                <label for="nicNo">NIC No</label>
                <input type="text" id="nicNo" formControlName="nicNo" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
            </span>
            <span class="ln-3">
                <label for="email" >Email Address</label>
                <input type="text" id="email" formControlName="email" pInputText placeholder="" readonly>
            </span>
            <span class="ln-3">
                <label for="contactNo" >Contact No</label>
                <input type="text" id="contactNo" formControlName="contactNo" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
            </span>
            <span class="ln-3">
                <label for="addressLineOne" >Address Line One</label>
                <input type="text" id="addressLineOne" formControlName="addressLineOne" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
            </span>
          <span class="ln-3">
                <label for="addressLineTwo" >Address Line Two</label>
                <input type="text" id="addressLineTwo" formControlName="addressLineTwo" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
            </span>
          <span class="ln-3">
                <label for="city" >City</label>
                <input type="text" id="city" formControlName="city" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';">
            </span>
            <span class="ln-1">
                <label for="about" >About (Optional)</label>
                <textarea type="text" id="about" formControlName="description" pInputText placeholder="" readonly="true" ondblclick="this.readOnly='';"></textarea>
            </span>

            <button (click)="updateProfile()" pButton pRipple type="button" class="p-button-outlined p-button-secondary"  label="Update Profile"></button>
        </form>
    </div>

    <div class="grid-three">
    </div>

</div>
