import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {DashboardService} from '../services/dashboard.service';
import {DataServiceService} from '../services/data-service.service';
import {NotificationsService} from 'angular2-notifications';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  emailRegEx = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  isLoginPage: boolean = true;
  selectedProfile: any;
  profiles = [
    {name: 'Select a profile', code: ''},
    {name: 'Admin', code: 'admin'},
    {name: 'Organizer', code: 'organizer'},
    {name: 'Participant', code: 'participant'},
    {name: 'Website', code: 'website'}
  ];

  UserRoles = {
    ADMIN: 'admin',
    USER: 'user',
    PARTICIPANT: 'participant',
    ORGANIZER: 'organizer'
  };

  signUpForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    role: new FormControl()
  });

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    public dashboardService: DashboardService,
    private dataService: DataServiceService,
    private notification: NotificationsService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.signUpForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      role: ['', [Validators.required]]
    });
  }

  clickLogin = (data: any) => {
    this.dataService.userLogin(data).toPromise().then(res => {
      const token = JSON.parse((JSON.stringify(res)));
      if (token.data) {
        sessionStorage.setItem('token', token.data);
        const loggedUser = JSON.parse(atob(token.data.split('.')[1]));
        if (loggedUser.data) {
          if (loggedUser.data.role === this.UserRoles.ADMIN) {
            this.userRedirect('/d/admin');
          }
          if (loggedUser.data.role === this.UserRoles.USER) {
            this.userRedirect('/');
          }
          if (loggedUser.data.role === this.UserRoles.ORGANIZER) {
            this.userRedirect('/d/organizer');
          }
          if (loggedUser.data.role === this.UserRoles.PARTICIPANT) {
            this.userRedirect('/d/participant');
          }
        }
      } else {
        console.log(token);
        this.notification.error('Error', token.details, {
          position: ['bottom', 'right'],
          timeOut: 3000,
          animate: 'fade',
          showprogressbar: true
        });
      }
    }).catch(e => {
      console.log(e);
      this.notification.error('', e.error.details, {
        position: ['bottom', 'right'],
        timeOut: 3000,
        animate: 'fade',
        showprogressbar: true
      });
    });
  };

  userRedirect = (url: string) => {
    this.router.navigateByUrl(url).then(() => {
      this.notification.success('Success!', 'you have successfully logged in', {
        position: ['bottom', 'right'],
        timeOut: 3000,
        animate: 'fade',
        showprogressbar: true
      });
    });
  };

  signUp() {
    this.isLoginPage = !this.isLoginPage;

  }

  createAccount() {
    const path = this.dataService.BASE_PATH + this.dataService.CREATE_ACCOUNT;

    if (this.signUpForm.valid) {
      this.http.post(path, this.signUpForm.value).toPromise().then(res => {
        this.notification.success('Success', 'Your account is created!', {
          position: ['bottom', 'right'],
          timeOut: 3000,
          animate: 'fade',
          showprogressbar: true
        });
        this.router.navigateByUrl('/login');
      }).catch(e => {
        console.log(e);
        this.notification.error('', e.error.details, {
          position: ['bottom', 'right'],
          timeOut: 3000,
          animate: 'fade',
          showprogressbar: true
        });
      });
    }
  }
}
