import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrganizerEventService} from '../organizer-event.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FireService} from '../../../common/services/fire.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';


@Component({
  selector: 'app-organizer-event-createnew-event',
  templateUrl: './organizer-event-createnew-event.component.html',
  styleUrls: ['./organizer-event-createnew-event.component.scss']
})
export class OrganizerEventCreatenewEventComponent implements OnInit {

  eventDetailsForm = new FormGroup({
    eventTitle: new FormControl(),
    registrationDeadline: new FormControl(),
    eventDate: new FormControl(),
    email: new FormControl(),
    eventLocationLink: new FormControl(),
    targetGroup: new FormControl()
  });

  socialMediaDetailsForm = new FormGroup({
    facebookUrl: new FormControl(),
    twitterUrl: new FormControl(),
    linkedinUrl: new FormControl(),
    websiteUrl: new FormControl()
  });
  organizerDetailsForm = new FormGroup({
    organizedBy: new FormControl(),
    addressLineOne: new FormControl(),
    addressLineTwo: new FormControl(),
    city: new FormControl()
  });

  requestedParticipantsDetailsForm = new FormGroup({
    requirements: new FormControl()
  });

  eventDescriptionForm = new FormGroup({
    description: new FormControl()
  });

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  participentDetails = [
    {id: 1, name: 'Name'},
    // {id: 2, name: 'School/Organization Name'},
    {id: 5, name: 'Email'},
    {id: 3, name: 'Contact No'},
    {id: 4, name: 'Tshirt Size'},
    {id: 4, name: 'Gender'},
    {id: 4, name: 'Food Preference'}
  ];

  eventBannerEvent: any;
  today = new Date();
  loggedUserToken: any;
  payment: any = '';
  paymentType: any = '';
  isSubmitting = false;
  isLoading = false;
  isDone = false;

  constructor(
    private router: Router,
    public eventService: OrganizerEventService,
    private fire: FireService,
    private fb: FormBuilder,
    private http: HttpClient,
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    this.createForm();
  }

  createForm() {
    this.eventDetailsForm = this.fb.group({
      eventTitle: ['', [Validators.required]],
      registrationDeadline: ['', [Validators.required]],
      eventDate: ['', [Validators.required]],
      email: ['', [Validators.required]],
      eventLocationLink: [''],
      targetGroup: ['', [Validators.required]]
    });

    this.socialMediaDetailsForm = this.fb.group({
      facebookUrl: [''],
      twitterUrl: [''],
      linkedinUrl: [''],
      websiteUrl: ['']
    });

    this.organizerDetailsForm = this.fb.group({
      organizedBy: [''],
      addressLineOne: [''],
      addressLineTwo: [''],
      city: ['']
    });

    this.requestedParticipantsDetailsForm = this.fb.group({
      requirements: ['']
    });
    this.eventDescriptionForm = this.fb.group({
      description: ['', [Validators.required]]
    });
  }


  btnClick = () => {
    // console.log(this.eventDetailsForm.value);
    // console.log(this.eventService.createNewEvent);
    this.eventService.createNewEvent.push(this.eventDetailsForm.value);
    this.eventService.createNewEvent.push(this.socialMediaDetailsForm.value);
    this.eventService.createNewEvent.push(this.organizerDetailsForm.value);
    this.eventService.createNewEvent.push(this.requestedParticipantsDetailsForm.value);
    this.eventService.createNewEvent.push(this.eventDescriptionForm.value);

    this.confirmationService.confirm({
      message: 'Are you sure that you want to create an event?',
      accept: () => {
        if (this.eventBannerEvent && this.eventDetailsForm.valid && this.socialMediaDetailsForm.valid && this.requestedParticipantsDetailsForm.valid && this.eventDescriptionForm.valid && this.organizerDetailsForm.valid) {
          this.fire.uploadBanners(this.eventBannerEvent);
          this.isSubmitting = true;
          // this.router.navigateByUrl('/d/organizer/organizer-event-createnew-payment');
          this.waitUntilUploadImage();
        }
      },
      reject: () => {
        // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  };

  waitUntilUploadImage = () => {
    if (this.eventService.createNewEvent.eventBanner) {
      this.clickSubmitButton();
    } else {
      setTimeout(this.waitUntilUploadImage, 10);
    }

  };

  uploadBanner = (banner: any) => {
    // 2097152 BYTE == 2MB
    if (banner.target.files[0].size < (1024 * 1024 * 2)) {
      if (banner.target.files[0].type == 'image/png' || banner.target.files[0].type == 'image/jpeg') {
        this.eventBannerEvent = banner;
      } else {
        // ToDo: Add notifications for ==>  Image type not PNG or JPG
      }
    } else {
      // ToDo: Add notifications for ==>  file size is greater than expected size
    }

  };

  clickSubmitButton = () => {
    this.isSubmitting = true;
    const newEvent = {
      paymentId: this.today.getTime().toString(),
      groupId: '',
      eventName: this.eventDetailsForm.value.eventTitle,
      organizerId: sessionStorage.getItem('orgId'),
      email: this.eventDetailsForm.value.email,
      formRequestedDetails: this.requestedParticipantsDetailsForm.value.requirements,
      description: this.eventDescriptionForm.value.description,
      startDateTime: this.eventDetailsForm.value.eventDate[0],
      endDateTime: this.eventDetailsForm.value.eventDate[1],
      registrationDeadline: this.eventDetailsForm.value.registrationDeadline,
      addressLineOne: this.organizerDetailsForm.value.addressLineOne,
      addressLineTwo: this.organizerDetailsForm.value.addressLineTwo,
      city: this.organizerDetailsForm.value.city,
      organizedBy: this.organizerDetailsForm.value.organizedBy,
      maxParticipants: '',
      registrationFee: '',
      category: '',
      targetGroup: this.eventDetailsForm.value.targetGroup,
      facebookUrl: this.socialMediaDetailsForm.value.facebookUrl,
      twitterUrl: this.socialMediaDetailsForm.value.twitterUrl,
      linkedinUrl: this.socialMediaDetailsForm.value.linkedinUrl,
      websiteUrl: this.socialMediaDetailsForm.value.websiteUrl,
      eventLocationLink: this.eventDetailsForm.value.eventLocationLink,
      eventBannerOne: this.eventService.createNewEvent.eventBanner,
      eventBannerTwo: '',
      requestSubmittedDate: this.today,
      status: 'pending',
      isPaid: false,
      isApproved: false
    };

    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    this.http.post(path, newEvent, header).toPromise().then(res => {
      // ToDo: Add Notification
      console.log(res);
      this.isLoading = true;
      this.isDone = true;
      setTimeout(() => {
        this.isSubmitting = false;
        this.isDone = false;
        this.eventDetailsForm.reset();
        this.socialMediaDetailsForm.reset();
        this.requestedParticipantsDetailsForm.reset();
        this.eventDescriptionForm.reset();
      }, 2000);
    }).catch(e => {
      // ToDo: Add Notification
      console.log(e);
    });
  };
}
