import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-sign-up-type-organizer',
  templateUrl: './sign-up-type-organizer.component.html',
  styleUrls: ['./sign-up-type-organizer.component.scss']
})
export class SignUpTypeOrganizerComponent implements OnInit {

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  gender = [
    {
      name: 'Male',
      code: 'M'
    },
    {
      name: 'Female',
      code: 'F'
    },
    {
      name: 'Other',
      code: 'O'
    }
  ]
  constructor(
    private primengConfig: PrimeNGConfig
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }


}
