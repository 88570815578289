<div class="aboutus-main">

    <div class="aboutus-img">
        <div class="aboutus-header">SLEMS</div>
    </div>

    <div class="aboutus-body">
        <div class="grid-one">
            <header>About Us</header>
            <p>The problem with most About Us pages is that they’re an afterthought—a link buried at the bottom of the
                page that leads to a few hastily written paragraphs about a company.</p>
            <p>What an About Us page should be is a goal-oriented sales page, one that focuses on highlighting the
                biggest selling points of your story and brand, making a strong first impression on curious customers
                In Shopify’s customer trust research, we found that shoppers navigate to an About Us page to learn more
                about the brand and the people behind the products. Your About Page should address those two curiosities
                shoppers have to help with decision making.</p>
            <p>Shoppers are also interested in a company’s mission. They’ll use the About Us page to determine if they
                share core values with the business and to decide if they want to shop with the business or not. </p>
        </div>
        <div class="grid-two">

            <form #form="ngForm">
                <header>Contact Us</header>

                <span class="ln-1">
                    <label for="firstName">Name</label>
                    <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
                </span>
                <span class="ln-1">
                    <label for="lastName" >Email</label>
                    <input type="email" id="lastName" name="lastName" ngModel pInputText placeholder="">
                </span>
                <span class="ln-1">
                    <label for="firstName">Message</label>
                    <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
                </span>

                <span style='padding-top: 20px;width: 100%;'>
                    <button pButton pRipple type="button" class="p-button-outlined p-button-secondary"  label="Submit"></button>
                </span>
                
            </form>
        </div>
    </div>





</div>