import { Component, OnInit } from '@angular/core';

export interface Application {
  id: string;
  name: string;
  NIC: string;
  address: string;
  tshirt: string;
  status: string;
}

@Component({
  selector: 'app-organizer-event-applications',
  templateUrl: './organizer-event-applications.component.html',
  styleUrls: ['./organizer-event-applications.component.scss']
})
export class OrganizerEventApplicationsComponent implements OnInit {

  first = 0;

  rows = 10;

  applications: Application[] = [
    { 'id': '0', 'name': 'John dee', 'NIC': '986553978V', 'address': 'Colombo', 'tshirt': 'small', 'status': 'reject' },
    { 'id': '1', 'name': 'Ann dee', 'NIC': '986553978V', 'address': 'Colombo', 'tshirt': 'large', 'status': 'accept' },
    { 'id': '2', 'name': 'John cena', 'NIC': '986553978V', 'address': 'Colombo', 'tshirt': 'medium', 'status': 'pending' }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
