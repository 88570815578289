<div class="footer-container">
    <div class="footer-grid">
        <div>SLEMS</div>
        <p>In addition to these pseudo-elements, there are also eleven pseudo-selector classes that aren’t required but provide designers with the power to style various states and interactions of the scrollbar UI. A full breakdown of those pseudo-selectors, and a detailed example, can be found in</p>
    </div>
    <div class="footer-grid">
        <h4>SOCIAL MEDIA</h4>
        <a href="">Facebook</a>
        <a href="">Twitter</a>
        <a href="">Linkedin</a>
        <a href="">Instagram</a>
    </div>
    <div class="footer-grid">
        <h4>ABOUT US</h4>
        <a routerLink = "about-us">About Us</a>
        <a href="">Features</a>
        <a href="">Privacy Policy</a>
        <a href="">Terms & Conditions</a>
    </div>
    <div class="footer-grid">
        <h4>SUPPORT</h4>
        <a routerLink="FAQ">F.A.Q</a>
    </div>
</div>
