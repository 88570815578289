import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-participants-search',
  templateUrl: './admin-participants-search.component.html',
  styleUrls: ['./admin-participants-search.component.scss']
})
export class AdminParticipantsSearchComponent implements OnInit {

  FoodPreference = [
    {
      name: 'Non-Veg',
      code: 'NVEG'
    },
    {
      name: 'Veg',
      code: 'VEG'
    }
  ];

  TshirtSize = [
    {
      name: 'Small',
      code: 'SM'
    },
    {
      name: 'Medium',
      code: 'MED'
    },
    {
      name: 'Large',
      code: 'LR'
    }
  ];

  Gender = [
    {
      name: 'Male',
      code: 'M'
    },
    {
      name: 'Female',
      code: 'F'
    },
    {
      name: 'Other',
      code: 'O'
    }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
