import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem, MessageService, PrimeNGConfig} from 'primeng/api';
import {DashboardModel} from '../models/dashboard-model';
import {DashboardService} from '../services/dashboard.service';

@Component({
    selector: 'app-dashboard-home',
    templateUrl: './dashboard-home.component.html',
    styleUrls: ['./dashboard-home.component.scss'],
    providers: [MessageService]
})
export class DashboardHomeComponent implements OnInit {

    @Input() menuContent: DashboardModel[] = [];

    items: MenuItem[] = [];
    menuItems: MenuItem[] = [];
    notifications: MenuItem[] = [];
    languages: MenuItem[] = [];
    titles: MenuItem[] = [];
    home :any;
    notificationCount: number = 0;

    constructor(
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private route: ActivatedRoute,
        private router: Router,
        public dashboardService: DashboardService
    ) {
    }


    ngOnInit(): void {
        this.titles = [
            {label: 'Computer'},
            {label: 'Notebook'},
            {label: 'Accessories'},
            {label: 'Backpacks'},
            {label: 'Item'}
        ];

        this.home = {icon: 'pi pi-home'};

        const profile = this.getProfile();
        if (profile === 'admin') {
            // admin tablist
            this.items = [
                {
                    label: 'Profile',
                    icon: 'pi pi-fw pi-user',
                    routerLink: 'admin/',
                    command: () => {
                        this.selectOneFromMenu('Profile');
                    }
                },
                {
                    label: 'Organizers',
                    icon: 'pi pi-fw pi-briefcase',
                    command: () => {
                        this.selectOneFromMenu('Organizers');
                    },
                    items: [
                        {label: 'Search', routerLink: 'admin/admin-organizers-search'},
                        {label: 'Event Requests', routerLink: 'admin/admin-organizers-approvals'},

                    ]
                },

                {
                    label: 'Participants',
                    icon: 'pi pi-fw pi-users',
                    command: () => {
                        this.selectOneFromMenu('Participants');
                    },
                    items: [
                        {label: 'Search', routerLink: 'admin/admin-participants-search'}
                    ]
                },
                {
                    label: 'Inquiries',
                    icon: 'pi pi-fw pi-question',
                    routerLink: 'admin/organization-inquiries',
                    command: () => {
                        this.selectOneFromMenu('Inquiries');
                    }

                },
                {
                    label: 'Reports',
                    icon: 'pi pi-fw pi-chart-line',
                    routerLink: 'admin/admin-analysis',
                    command: () => {
                        this.selectOneFromMenu('Reports');
                    },
                    items: [
                        {label: 'Overview'},
                        {label: 'Organizers'},
                        {label: 'Participants'},

                    ]
                },
                {
                    label: 'Settings',
                    icon: 'pi pi-fw pi-cog',
                    routerLink: 'admin/admin-settings',
                    command: () => {
                        this.selectOneFromMenu('Settings');
                    }


                }
            ];
        } else if (profile === 'organizer') {
            // organizer tablist
            this.items = [
                {
                    label: 'Profile',
                    icon: 'pi pi-fw pi-user',
                    routerLink: 'organizer/',
                    command: () => {
                        this.selectOneFromMenu('Profile');
                    }
                },
                {
                    label: 'Event',
                    icon: 'pi pi-fw pi-users',
                    routerLink: 'organizer/organizer-event',
                    command: () => {
                        this.selectOneFromMenu('Event');
                    }
                },
                {
                    label: 'Payments',
                    icon: 'pi pi-fw pi-chart-line',
                    routerLink: 'organizer/organizer-payment',
                    command: () => {
                        this.selectOneFromMenu('Payments');
                    }
                },
                {
                    label: 'Inquiries',
                    icon: 'pi pi-fw pi-question',
                    routerLink: 'organizer/organizer-inquiries',
                    command: () => {
                        this.selectOneFromMenu('Inquiries');
                    }

                },
                {
                    label: 'Settings',
                    icon: 'pi pi-fw pi-cog',
                    routerLink: 'organizer/organizer-settings',
                    command: () => {
                        this.selectOneFromMenu('Settings');
                    }


                }
            ];
        } else if (profile === 'participant') {
            // participant tablist
            this.items = [
                {
                    label: 'Profile',
                    icon: 'pi pi-fw pi-user',
                    routerLink: 'participant/',
                    command: () => {
                        this.selectOneFromMenu('Profile');
                    }
                },
                {
                    label: 'Event',
                    icon: 'pi pi-fw pi-users',
                    routerLink: 'participant/participant-event',
                    command: () => {
                        this.selectOneFromMenu('Event');
                    }
                },
                {
                    label: 'Inquiries',
                    icon: 'pi pi-fw pi-question',
                    routerLink: 'participant/participant-inquiries',
                    command: () => {
                        this.selectOneFromMenu('Inquiries');
                    }

                },
                {
                    label: 'Settings',
                    icon: 'pi pi-fw pi-cog',
                    routerLink: 'participant/participant-settings',
                    command: () => {
                        this.selectOneFromMenu('Settings');
                    }


                }
            ];
        }

        this.menuItems = [
            {
                label: 'Current User',
                items: [
                    {
                        label: 'Lakshan',
                        icon: 'pi pi-user',
                        command: () => {
                            this.update();
                        }
                    },
                    {
                        label: 'lakshan@strewlabs.com',
                        icon: 'pi pi-envelope',
                        command: () => {
                            this.delete();
                        }
                    }
                ]
            },
            {
                label: 'Navigate',
                items: [
                    {
                        label: 'Logout',
                        icon: 'pi pi-sign-out',
                        url: '/login'
                    }
                ]
            }
        ];
        this.notifications = [
            {
                label: 'Notification',
                items: [
                    {
                        label: 'Google I/O 2022 | Coming Soon',
                        icon: 'pi pi-calendar'
                    },
                    {
                        label: 'IEEE Hackathon | Registration Open',
                        icon: 'pi pi-envelope'
                    }
                ]
            }
        ];

        this.languages = [
            {
                label: 'Select a language',
                items: [
                    {
                        label: 'English',
                        icon: 'pi pi-star-o'
                    },
                    {
                        label: 'Sinhala',
                        icon: 'pi pi-star-o'
                    },
                    {
                        label: 'Chinese',
                        icon: 'pi pi-star-o'
                    }
                ]
            }
        ];
    }

    update() {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Data Updated'});
    }

    delete() {
        this.messageService.add({severity: 'warn', summary: 'Delete', detail: 'Data Deleted'});
    }

    getProfile(): string {
        const currentUrl = this.router.url;
        let user: string = '';
        if (currentUrl.includes('/d/admin')) {
            user = 'admin';
        } else if (currentUrl.includes('/d/organizer')) {
            user = 'organizer';
        } else if (currentUrl.includes('/d/participant')) {
            user = 'participant';
        }
        return user;
    }

    selectOneFromMenu(title: any) {
        this.dashboardService.DASHBOARD_HEADER = title;
    }
}
