import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrganizerEventService} from '../organizer-event.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-organizer-event-createnew-payment',
  templateUrl: './organizer-event-createnew-payment.component.html',
  styleUrls: ['./organizer-event-createnew-payment.component.scss']
})
export class OrganizerEventCreatenewPaymentComponent implements OnInit {

/**
  =========================================================
  ========| Deprecated Component |=======
 =========================================================
 =========================================================
 **/

today = new Date();
  loggedUserToken: any;
  payment: any = '';
  paymentType: any = '';


  constructor(
    private router: Router,
    public eventService: OrganizerEventService,
    private http: HttpClient,
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
  }

  clickBackButton = () => {
    this.router.navigateByUrl('/d/organizer/organizer-event-createnew-event');
  };

  clickSubmitButton = () => {
    const data: any = [];
    const newEvent = {
      paymentId: this.today.getTime().toString(),
      groupId: '',
      eventName: this.eventService.createNewEvent[0].eventTitle,
      organizerId: sessionStorage.getItem('orgId'),
      email: this.eventService.createNewEvent[0].email,
      formRequestedDetails: this.eventService.createNewEvent[2].requirements,
      description: this.eventService.createNewEvent[3].description,
      startDateTime: this.eventService.createNewEvent[0].eventDate[0],
      endDateTime: this.eventService.createNewEvent[0].eventDate[1],
      registrationDeadline: this.eventService.createNewEvent[0].registrationDeadline,
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      organizedBy: '',
      maxParticipants: '',
      registrationFee: '',
      category: '',
      targetGroup: this.eventService.createNewEvent[0].targetGroup,
      facebookUrl: this.eventService.createNewEvent[1].facebookUrl,
      twitterUrl: this.eventService.createNewEvent[1].twitterUrl,
      linkedinUrl: this.eventService.createNewEvent[1].linkedinUrl,
      websiteUrl: this.eventService.createNewEvent[1].websiteUrl,
      eventLocationLink: this.eventService.createNewEvent[0].eventLocationLink,
      eventBannerOne: this.eventService.createNewEvent.eventBanner,
      eventBannerTwo: '',
      requestSubmittedDate: this.today,
      status: 'pending',
      isPaid: false,
      isApproved: false
    };
    console.log(this.eventService.createNewEvent);
    console.log(this.payment, this.paymentType);

    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};

    this.confirmationService.confirm({
      message: 'Are you sure that you want to create an event?',
      accept: () => {
        this.http.post(path, newEvent, header).toPromise().then(res => {
          // ToDo: Add Notification
          console.log(res);
        }).catch(e => {
          // ToDo: Add Notification
          console.log(e);
        });
      },
      reject: () => {
        // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  };
}
