<div class="stepsdemo-content">
  <div class="main">
    <div class="grid-one">
      <span class="header">Organization Details</span>
      <form [formGroup]="organizationForm">
        <span class="ln-1">
          <label for="organizationName" style="display: block; margin-bottom: 4px"
          >Organization Name</label
          >
          <input
            type="text"
            id="organizationName"
            formControlName="organizationName"
            pInputText
            placeholder=""
            readonly
          />
        </span>
        <span class="ln-2">
          <label for="regNo" style="display: block; margin-bottom: 4px"
          >Reg. No (If any)</label
          >
          <input
            type="text"
            id="regNo"
            formControlName="regNo"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-2">
          <label for="organizationContactNo" style="display: block; margin-bottom: 4px"
          >Contact No</label
          >
          <input
            type="text"
            id="organizationContactNo"
            formControlName="organizationContactNo"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-2">
          <label for="organizationEmail" style="display: block; margin-bottom: 4px"
          >Email Address</label
          >
          <input
            type="text"
            id="organizationEmail"
            formControlName="organizationEmail"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-2">
          <label style="display: block; margin-bottom: 4px"
          >Organization Type</label
          >
           <input
             type="text"
             id="organizationType"
             formControlName="organizationType"
             readonly
             pInputText
             placeholder=""
           />
<!--          <p-dropdown-->
<!--            [options]="OrganizationType"-->
<!--            formControlName="OrganizationType"-->
<!--            ngModel-->
<!--            placeholder="Select"-->
<!--            optionLabel="formControlName="" "-->
<!--            [showClear]="true"-->
<!--          ></p-dropdown> -->
        </span
        ><br/>
        <span class="ln-1">
          <label for="address" style="display: block; margin-bottom: 4px"
          >Address</label
          >
          <input
            type="text"
            id="address"
            formControlName="address"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-1">
          <label for="about" style="display: block; margin-bottom: 4px"
          >About (Optional)</label
          >
          <textarea
            type="text"
            id="about"
            formControlName="about"
            readonly
            pInputText
            placeholder=""
          ></textarea>
        </span>
      </form>
    </div>

    <div class="grid-two">
      <span class="header">Representative's Information</span>
      <form [formGroup]="representativeForm">
        <span class="ln-1">
          <label for="name" style="display: block; margin-bottom: 4px"
          >Name</label
          >
          <input
            type="text"
            id="name"
            formControlName="name"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-1">
          <label for="nic" style="display: block; margin-bottom: 4px"
          >NIC No.</label
          >
          <input
            type="text"
            id="nic"
            formControlName="nic"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-1">
          <label for="email" style="display: block; margin-bottom: 4px"
          >Personal Email</label
          >
          <input
            type="text"
            id="email"
            formControlName="email"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-1">
          <label for="contactNo" style="display: block; margin-bottom: 4px"
          >Contact No</label
          >
          <input
            type="text"
            id="contactNo"
            formControlName="contactNo"
            readonly
            pInputText
            placeholder=""
          />
        </span>
      </form>
    </div>
  </div>
  <div class="p-grid p-nogutter p-justify-end">
    <p-button
      class="second-button"
      label="Next"
      (onClick)="nextPage()"
      icon="pi pi-angle-right"
      iconPos="right"
    ></p-button>
  </div>
</div>

<!-- error validation -->
<!-- <div class="p-field">
  <label for="age">Age</label>
  <input #age="ngModel" id="age" type="number" required pInputText  [ngClass]="{'ng-dirty': (age.invalid && submitted) || (age.dirty && age.invalid)}">
  <small class="p-error" *ngIf="(age.invalid && submitted) || (age.dirty && age.invalid)">Age is required.</small>
</div> -->
