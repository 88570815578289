import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrganizerEventService} from '../../organizer-event.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  eventDetailsForm = new FormGroup({
    eventTitle: new FormControl(),
    registrationDeadline: new FormControl(),
    eventDate: new FormControl(),
    email: new FormControl(),
    // targetGroup: new FormControl(),
    eventLocationLink: new FormControl(),
    eventDescription: new FormControl()
  });

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  participentDetails = [
    {id: 1, name: 'Name'},
    {id: 2, name: 'School/Organization Name'},
    {id: 5, name: 'Email'},
    {id: 3, name: 'Contact No'},
    {id: 4, name: 'Tshirt Size'},
    {id: 4, name: 'Gender'},
    {id: 4, name: 'Food Preference'}
  ];

  constructor(
    private router: Router,
    public organizerEventService: OrganizerEventService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    if (this.organizerEventService.currentEventMore) {

      this.eventDetailsForm = this.fb.group({
        eventTitle: [this.organizerEventService.currentEventMore.eventName],
        registrationDeadline: [new Date(this.organizerEventService.currentEventMore.registrationDeadline).toLocaleDateString() + ' ' + new Date(this.organizerEventService.currentEventMore.registrationDeadline).toLocaleTimeString()],
        eventDate: [new Date(this.organizerEventService.currentEventMore.startDateTime).toLocaleDateString() + ' - ' + new Date(this.organizerEventService.currentEventMore.endDateTime).toLocaleDateString()],
        email: [this.organizerEventService.currentEventMore.organizerId.email],
        // targetGroup:[this.organizerEventService.currentEventMore],
        eventLocationLink: [this.organizerEventService.currentEventMore.eventLocationLink],
        eventDescription: [this.organizerEventService.currentEventMore.description]
      });
    }
  }

  nextPage() {
    this.router.navigate(['d/organizer/organizer-event/event-payment-details']);
  }

  prevPage() {
    this.router.navigate(['d/organizer/organizer-event/event-organizer-details']);
  }

}
