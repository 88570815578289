<div class="stepsdemo-content">
  <div class="main">
    <div>
      <span class="header">Payment Details</span>
      <form [formGroup]="paymentForm">
      <span class="ln-2">
        <label for="receiptNo" style="display: block; margin-bottom: 4px"
        >Receipt No</label
        >
        <input
          type="text"
          id="receiptNo"
          formControlName="receiptNo"
          readonly
          pInputText
          placeholder=""
        />
      </span>
        <span class="ln-2">
        <label for="amount" style="display: block; margin-bottom: 4px"
        >Amount (LKR)</label
        >
        <input
          type="text"
          id="amount"
          formControlName="amount"
          readonly
          pInputText
          placeholder=""
        />
      </span>

        <span class="ln-2">
        <label for="dateTime" style="display: block; margin-bottom: 4px"
        >Deposit/ Transfer Date</label
        >
        <input
          type="text"
          id="dateTime"
          formControlName="dateTime"
          readonly
          pInputText
          placeholder=""
        />
      </span>
        <span class="ln-2">
        <label for="bankName" style="display: block; margin-bottom: 4px"
        >Bank Name</label
        >
        <input
          type="text"
          id="bankName"
          formControlName="bankName"
          readonly
          pInputText
          placeholder=""
        />
      </span>

        <span class="ln-1">
        <label for="receiptImage" style="display: block; margin-bottom: 4px"
        >Receipt Image</label
        >
        <input
          type="text"
          id="receiptImage"
          formControlName="receiptImage"
          readonly
          pInputText
          placeholder=""
        />
      </span>
        <span class="ln-1">
        <label for="description" style="display: block; margin-bottom: 4px"
        >Notes</label
        >
        <textarea
          type="text"
          id="description"
          formControlName="description"
          readonly
          pInputText
          placeholder=""
        ></textarea>
      </span>
      </form>
      <div
        class="p-grid p-nogutter p-justify-end"
        style="width: 50%; margin: auto; margin-top: 20px"
      >
        <p-button
          label="Back"
          (onClick)="prevPage()"
          icon="pi pi-angle-left"
        ></p-button>
        <p-button
          class="second-button"
          label="Close"
          (onClick)="closePage()"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
    <div class="action-container">
      <span class="header">Actions</span>
      <p-button
        class="action-button"
        label="Accept"
        (onClick)="takeAction('active')"
        icon=""
      ></p-button>
      <p-button
        class="action-button"
        label="Pending"
        (onClick)="takeAction('pending')"
        icon=""
      ></p-button>
      <p-button
        class="action-button"
        label="Reject"
        (onClick)="takeAction('reject')"
        icon=""
      ></p-button>
    </div>
  </div>
</div>
