<div class="stepsdemo-content">
  <div class="main">
    <div class="grid-one">
      <span class="header">Event Details</span>
      <form [formGroup]="eventDetailForm">
        <span class="ln-1">
          <label for="title" style="display: block; margin-bottom: 4px"
          >Event Title</label
          >
          <input
            type="text"
            id="title"
            formControlName="title"
            readonly
            pInputText
            placeholder=""
          />
        </span>

        <span class="ln-2">
          <label for="registrationDeadline" style="display: block; margin-bottom: 4px"
          >Registration Deadline</label
          >
          <input
            type="text"
            id="registrationDeadline"
            formControlName="registrationDeadline"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-2">
          <label for="eventDate" style="display: block; margin-bottom: 4px"
          >Event Date</label
          >
          <input
            type="text"
            id="eventDate"
            formControlName="eventDate"
            readonly
            pInputText
            placeholder=""
          />
        </span>

        <span class="ln-2">
          <label for="email" style="display: block; margin-bottom: 4px"
          >Email Address</label
          >
          <input
            type="text"
            id="email"
            formControlName="email"
            readonly
            pInputText
            placeholder=""
          />
        </span>

        <span class="ln-2">
          <label style="display: block; margin-bottom: 4px"
          >Targeted Group</label
          >
          <p-multiSelect
            [options]="targetGroup"
            defaultLabel="expand to view"
            optionLabel="name"
            display="chip"
          >
          </p-multiSelect>
        </span>

        <span class="ln-1">
          <label for="eventLocationLink" style="display: block; margin-bottom: 4px"
          >event Location Link</label
          >
          <input
            type="text"
            id="eventLocationLink"
            formControlName="eventLocationLink"
            readonly
            pInputText
            placeholder=""
          />
        </span>
        <span class="ln-1">
          <label for="eventDescription" style="display: block; margin-bottom: 4px"
          >Event Description</label
          >
          <textarea
            type="text"
            id="eventDescription"
            formControlName="eventDescription"
            readonly
            pInputText
            placeholder=""
          ></textarea>
        </span>
      </form>
    </div>

    <div class="grid-two">
      <span class="header">Requested Participant's Details</span>
      <form #form="ngForm">
        <ul *ngIf="participentDetails">

        <span class="ln-1" *ngFor="let pDetail of participentDetails">
          <li>
          {{pDetail | titlecase}}
            <!--          <p-checkbox-->
            <!--            formControlName="group1"-->
            <!--            value="{{ pdetail.name }}"-->
            <!--            inputId="ny"-->
            <!--          ></p-checkbox>-->
            <!--          <label for="binary"> {{ pdetail.name }} </label>-->
          </li>
        </span>

        </ul>
      </form>
    </div>
  </div>
  <div class="p-grid p-nogutter p-justify-end">
    <p-button
      label="Back"
      (onClick)="prevPage()"
      icon="pi pi-angle-left"
    ></p-button>
    <p-button
      class="second-button"
      label="Next"
      (onClick)="nextPage()"
      icon="pi pi-angle-right"
      iconPos="right"
    ></p-button>
  </div>
</div>
