// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_PATH: 'http://localhost:3000',
  // BASE_PATH: 'https://us-central1-sl-event-management-platform.cloudfunctions.net/app',

  // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyB1f0OkVKO2MfiDYvFeG-RzqA46cyWi5XI',
    authDomain: 'sl-event-management-platform.firebaseapp.com',
    projectId: 'sl-event-management-platform',
    storageBucket: 'sl-event-management-platform.appspot.com',
    messagingSenderId: '522806421200',
    appId: '1:522806421200:web:5a23d8cd23e88b021d60d5',
    measurementId: 'G-82VGWKWCNE'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
