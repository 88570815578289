import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AdminEventService} from '../../admin-event.service';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-approval-payment-details',
  templateUrl: './approval-payment-details.component.html',
  styleUrls: ['./approval-payment-details.component.scss'],
  providers: [DatePipe]
})
export class ApprovalPaymentDetailsComponent implements OnInit {
  // To Do: Need to add Receipt Image to this form
  paymentForm = new FormGroup({
    receiptNo: new FormControl(),
    amount: new FormControl(),
    dateTime: new FormControl(),
    bankName: new FormControl(),
    receiptImage: new FormControl(),
    description: new FormControl()
  });

  loggedUserToken: any;

  constructor(
    private router: Router,
    private dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    public adminEventService: AdminEventService,
    private datePipe: DatePipe,
    private http: HttpClient,
    public dataService: DataServiceService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));

    this.paymentForm = this.fb.group({
      receiptNo: [this.adminEventService.currentEvent.paymentId.receiptNo],
      amount: [this.adminEventService.currentEvent.paymentId.amount],
      dateTime: [this.datePipe.transform(this.adminEventService.currentEvent.paymentId.dateTime, 'short')],
      receiptImage: [''],
      bankName: [this.adminEventService.currentEvent.paymentId.bankName],
      description: [this.adminEventService.currentEvent.paymentId.description]
    });
  }

  closePage() {
    this.router.navigate(['/d/admin/admin-organizers-approvals']);
    this.dialogRef.close();
  }

  prevPage() {
    this.router.navigate(['/d/admin/admin-organizers-approvals/event-details']);
  }

  takeAction(action: any) {

    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    const data = {
      _id: this.adminEventService.currentEvent._id,
      status: action
    };

    this.http.put(path, data, header).toPromise().then(res => {
      // ToDo: Add Notification
    }).catch(e => {
      // ToDo: Add Notification
      console.log(e);
    });

    // this.confirmationService.confirm({
    //   message: 'Are you sure that you want to ' + action + ' this event?',
    //   accept: () => {
    //     this.http.put(path, data, header).toPromise().then(res => {
    //       // ToDo: Add Notification
    //     }).catch(e => {
    //       // ToDo: Add Notification
    //       console.log(e);
    //     });
    //   },
    //   reject: () => {
    //     // ToDo: add something if needed to take any action when rejecting the confirmation
    //   }
    // });
  }
}
