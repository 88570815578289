import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-join-group',
  templateUrl: './event-join-group.component.html',
  styleUrls: ['./event-join-group.component.scss']
})
export class EventJoinGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
