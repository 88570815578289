import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';

@Component({
  selector: 'app-website-advertisement',
  templateUrl: './website-advertisement.component.html',
  styleUrls: ['./website-advertisement.component.scss']
})
export class WebsiteAdvertisementComponent implements OnInit {

  totalRecords = 0;

  cities = [
    {name: 'All', code: 'NY'},
    {name: 'Rome', code: 'RM'},
    {name: 'London', code: 'LDN'},
    {name: 'Istanbul', code: 'IST'},
    {name: 'Paris', code: 'PRS'}
  ];

  images: any[] = [
    // {
    //   'previewImageSrc': 'assets/event01.jpg',
    //   'alt': 'Description for Image 1',
    //   'title': 'Title 1'
    // },
    // {
    //   'previewImageSrc': 'assets/event02.png',
    //   'alt': 'Description for Image 2',
    //   'title': 'Title 2'
    // },
    // {
    //   'previewImageSrc': 'assets/event03.jpg',
    //   'alt': 'Description for Image 3',
    //   'title': 'Title 3'
    // },
  ];

  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  events: any = [];
  pageEvents: any = [];

  constructor(
    private http: HttpClient,
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (!sessionStorage.getItem('events') || sessionStorage.getItem('events') == null) {
      this.loadEventsData();
    } else {
      const data = sessionStorage.getItem('events');
      if (data != null) {
        this.events = JSON.parse(data);
        this.pageEvents = this.events;
        this.pageEvents = this.pageEvents.slice(0, 10);
      }
    }

    this.loadBanners();

  }

  loadBanners() {
    const data = sessionStorage.getItem('events');
    let evt = [];
    if (data != null) {
      evt = JSON.parse(data);
    }
    this.images = evt.slice(0, 4);
  }


  loadEventsData() {
    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT_SEARCH;
    const searchCriteria = {
      "status":"active"
    };

    this.http.post(path, searchCriteria).toPromise().then(re => {
      this.events = JSON.parse(JSON.stringify(re)).data;
      sessionStorage.setItem('events', JSON.stringify(this.events));
      this.pageEvents = this.events.slice(0, 10);
    }).catch(e => {
      console.log(e);
      // ToDo: Add Notification
    });
  }

  paginate = (event: any) => {
    const data = sessionStorage.getItem('events');
    if (data != null) {
      this.events = JSON.parse(data);
    }
    this.pageEvents = this.events;
    this.totalRecords = this.events.length;

    const start = event.first;
    const end = event.first + event.rows;
    if (end > this.events.length) {
      this.pageEvents = this.pageEvents.slice(start, this.events.length);
    } else {
      this.pageEvents = this.pageEvents.slice(start, end);
    }
  };

  eventClicked(event: any) {
    sessionStorage.setItem('selectedEvent', JSON.stringify(event));
    this.dataService.SELECTED_EVENT = event;
    if(event.groupId != null) {
      setTimeout(() => {
        this.router.navigateByUrl('event/individual');
      }, 0);
    } else {
      setTimeout(() => {
        this.router.navigateByUrl('event/group');
      }, 0);
    }
  }
}
