<div class="stepsdemo-content">
  <div class="main">

    <div class="grid-one">
      <span class="header">Organization Details</span>
      <form [formGroup]="organizationDetailsForm">
                <span class="ln-1">
                    <label for="organizationName" style="display:block;margin-bottom:4px">Organization Name</label>
                    <input readonly type="text" id="organizationName" formControlName="organizationName" pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="regNo" style="display:block;margin-bottom:4px">Reg. No (If any)</label>
                    <input readonly type="text" id="regNo" formControlName="regNo" pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="organizationContactNo" style="display:block;margin-bottom:4px">Contact No</label>
                    <input readonly type="text" id="organizationContactNo" formControlName="organizationContactNo" pInputText
                           placeholder="">
                </span>
        <span class="ln-2">
                    <label for="organizationEmail" style="display:block;margin-bottom:4px">Email Address</label>
                    <input readonly type="text" id="organizationEmail" formControlName="organizationEmail" pInputText
                           placeholder="">
                </span>
        <span class="ln-2">
                    <label for="organizationType" style="display:block;margin-bottom:4px">Organization Type</label>
                    <input readonly type="text" id="organizationType" formControlName="organizationType" pInputText placeholder="">
                </span><br>
        <span class="ln-1">
                    <label for="organizationAddress" style="display:block;margin-bottom:4px">Address</label>
                    <input readonly type="text" id="organizationAddress" formControlName="organizationAddress" pInputText
                           placeholder="">
                </span>
        <span class="ln-1">
                    <label for="description" style="display:block;margin-bottom:4px">About (Optional)</label>
                    <textarea readonly type="text" id="description" formControlName="description" pInputText
                              placeholder=""></textarea>
                </span>
      </form>
    </div>

    <div class="grid-two">
      <span class="header">Representative's Information</span>
      <form [formGroup]="representativeDetailsForm">
                <span class="ln-1">
                    <label for="name" style="display:block;margin-bottom:4px">Name</label>
                    <input readonly type="text" id="name" formControlName="name" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="nic" style="display:block;margin-bottom:4px">NIC No.</label>
                    <input readonly type="text" id="nic" formControlName="nic" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="email" style="display:block;margin-bottom:4px">Personal Email</label>
                    <input readonly type="text" id="email" formControlName="email" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="contactNo" style="display:block;margin-bottom:4px">Contact No</label>
                    <input readonly type="text" id="contactNo" formControlName="contactNo" pInputText placeholder="">
                </span>
      </form>
    </div>
  </div>
  <div class="p-grid p-nogutter p-justify-end">
    <p-button label="Next" (onClick)="nextPage()" icon="pi pi-angle-right"
              iconPos="right"></p-button>
  </div>

</div>


<!-- error validation -->
<!-- <div class="p-field">
    <label for="age">Age</label>
    <input #age="" id="age" type="number" required pInputText  [ngClass]="{'ng-dirty': (age.invalid && submitted) || (age.dirty && age.invalid)}">
    <small class="p-error" *ngIf="(age.invalid && submitted) || (age.dirty && age.invalid)">Age is required.</small>
</div> -->
