<div class="event-main">

  <div class="header">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Search Event" />
        </span>
        <button pButton pRipple type="button" (click)="btnClick();" class="p-button-outlined p-button-secondary" label="Create New"></button>
    </div>

  <p-table [value]="events" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [rowsPerPageOptions]="[10,25,50]">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 15%;">
          Event No
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Event Name
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Payments
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Event Date
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Status
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th style="width: 10%;">More</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr>
        <td>{{event.requestSubmittedDate | date: 'mediumDate'}}</td>
        <td>{{event.eventName}}</td>
        <td><span [class]="'status status-' + (event.isPaid ? 'paid':'not-paid')">{{(event.isPaid ? 'paid':'pending') | titlecase}}</span></td>
        <td>{{event.startDateTime | date: 'medium'}}<br><span class="l-2">{{event.endDateTime | date: 'medium'}}</span></td>
        <td><span [class]="'status status-' + event.status">{{event.status}}</span></td>
        <td (click)="menu.toggle($event); moreClick(event)" ><span class="more-span pi pi-ellipsis-v" ></span></td>
      </tr>
    </ng-template>
  </p-table>
</div>


<p-menu #menu [popup]="true" [model]="moreitems"></p-menu>
