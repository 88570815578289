import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminEventService {

  public currentEvent: any;

  constructor() {
  }
}
