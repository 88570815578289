<!--
   * This is the Public Website.
   * Please do any kind of implementation regards to the EMP-website under the website folder.
   * If you have any clarifications feel free to contact me (Lakshan)
 -->

<app-website-header></app-website-header>

<router-outlet></router-outlet>

<app-website-footer></app-website-footer>

<!-- <button pButton type="button" routerLink="/login" >Login</button>
<button pButton type="button" routerLink="" >Website</button>
<button pButton type="button" routerLink="d/admin" >Admin</button>
<button pButton type="button" routerLink="d/organizer" >Organizer</button>
<button pButton type="button" routerLink="d/participant" >Participant</button> -->
