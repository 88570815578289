import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-organizer-event-details',
  templateUrl: './organizer-event-details.component.html',
  styleUrls: ['./organizer-event-details.component.scss']
})
export class OrganizerEventDetailsComponent implements OnInit {

  items: MenuItem[] = [];
  

  constructor() { }

  ngOnInit(): void {
    this.items = [{
      label: 'Organization Details',
      routerLink: '/d/organizer/organizer-event/event-organizer-details'
    },
    {
      label: 'Event Details',
      routerLink: '/d/organizer/organizer-event/event-details'
    },
    {
      label: 'Payments',
      routerLink: '/d/organizer/organizer-event/event-payment-details'
    }
    ];
  }

}
