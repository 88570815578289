<p-carousel [value]="images" [circular]="true" [autoplayInterval]="3000">
  <ng-template pTemplate="item" let-item>
    <img [src]="item.eventBannerOne" style="width: 100%;height: 600px; display: block;" [alt]="item.eventName"/>
  </ng-template>
</p-carousel>

<div class="filter-bar">
  <div style="display: inline;">
    <span style="padding-right: 20px;">Filter By :</span>
    <span style="padding-right: 20px;">
            Location :
            <p-dropdown styleClass="dropdown" [options]="cities" optionLabel="name" [filter]="true" filterBy="name"
                        [showClear]="true"></p-dropdown>
        </span>
    <span style="padding-right: 20px;">
            category :
            <p-dropdown styleClass="dropdown" [options]="cities" optionLabel="name" [filter]="true" filterBy="name"
                        [showClear]="true"></p-dropdown>
        </span>
    <span style="padding-right: 20px;">
            Event code :
            <p-dropdown styleClass="dropdown" [options]="cities" optionLabel="name" [filter]="true" filterBy="name"
                        [showClear]="true"></p-dropdown>
        </span>
  </div>


  <div style="display: inline;float: right;">
        <span>
            <button pButton type="button">Find</button>
        </span>
  </div>
</div>


<div class="body">

  <div class="card-div" *ngFor="let event of pageEvents; let i = index;">
    <div class="banner-status-bar-div">
      <div class="banner-status-bar-div-2">
        <div class="banner-status-bar">
          {{(event.status ? event.status : '') | titlecase}}
        </div>
      </div>
    </div>
    <p-card class="p-col" styleClass="p-card-shadow" (click)="eventClicked(event)">
      <ng-template pTemplate="header">
        <div class="div-img">
          <img alt="Card" class="card-img" [src]="event.eventBannerOne ? event.eventBannerOne : ''">
        </div>
      </ng-template>
      <div class="card-header">{{event.eventName ? event.eventName : ''}}</div>
      <div id="date"
           class="card-body">
        <i class="pi pi-calendar-plus"></i>
        {{(event.startDateTime ? event.startDateTime : '') | date: 'MMM d, y, h:mm a'}}
      </div>
      <div id="venue"
           class="card-body">
        <i class="pi pi-globe"></i>
        {{event.addressLineOne ? event.addressLineOne : ""}}
        {{event.addressLineTwo ? ", " + event.addressLineTwo : ""}}
        {{event.city ? ", " + event.city : ""}}
      </div>
      <div id="organization" class="card-body">
        <i class="pi pi-users"></i>
        {{event.organizedBy ? event.organizedBy : ''}}
      </div>
    </p-card>
  </div>
</div>
<p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
             (onPageChange)="paginate($event)"></p-paginator>
