import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './common/login/login.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { SignUpTypeHomeComponent } from './common/sign-up-type/sign-up-type-home/sign-up-type-home.component';
import { SignUpTypeOrganizerComponent } from './common/sign-up-type/sign-up-type-organizer/sign-up-type-organizer.component';
import { SignUpTypeParticipantComponent } from './common/sign-up-type/sign-up-type-participant/sign-up-type-participant.component';
import { SignUpTypeComponent } from './common/sign-up-type/sign-up-type.component';
import { ParticipantMainComponent } from './participant/participant-main/participant-main.component';
import { WebsiteMainComponent } from './website/website-main/website-main.component';
import { DashboardHomeComponent } from './common/dashboard-home/dashboard-home.component';
import { AdminMainComponent } from './admin/admin-main/admin-main.component';

import { InquiryListComponent } from './common/inquiry-list/inquiry-list.component';
import { CreateInquiryComponent } from './common/create-inquiry/create-inquiry.component';

import { OrganizerMainComponent } from './organizer/organizer-main/organizer-main.component';
import { OrganizerProfileComponent } from './organizer/organizer-profile/organizer-profile.component';
import { OrganizerEventComponent } from './organizer/organizer-event/organizer-event.component';
import { OrganizerEventApplicationsComponent } from './organizer/organizer-event/organizer-event-applications/organizer-event-applications.component';
import { OrganizerEventCreatenewEventComponent } from './organizer/organizer-event/organizer-event-createnew-event/organizer-event-createnew-event.component';
import { OrganizerEventCreatenewPaymentComponent } from './organizer/organizer-event/organizer-event-createnew-payment/organizer-event-createnew-payment.component';
import { EventOrganizationDetailsComponent } from './organizer/organizer-event/organizer-event-details/event-organization-details/event-organization-details.component';
import { EventDetailsComponent } from './organizer/organizer-event/organizer-event-details/event-details/event-details.component';
import { EventPaymentDetailsComponent } from './organizer/organizer-event/organizer-event-details/event-payment-details/event-payment-details.component';
import { OrganizerPaymentComponent } from './organizer/organizer-payment/organizer-payment.component';

import { AdminSettingsComponent } from './admin/admin-settings/admin-settings.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { AdminOrganizersSearchComponent } from './admin/admin-organizers-search/admin-organizers-search.component';
import { AdminOrganizersCreatenewComponent } from './admin/admin-organizers-createnew/admin-organizers-createnew.component';
import { AdminOrganizersApprovalsComponent } from './admin/admin-organizers-approvals/admin-organizers-approvals.component';
import { AdminParticipantsSearchComponent } from './admin/admin-participants-search/admin-participants-search.component';
import { AdminParticipantsCreatenewComponent } from './admin/admin-participants-createnew/admin-participants-createnew.component';
import { AdminInquiriesComponent } from './admin/admin-inquiries/admin-inquiries.component';
import { AdminAnalysisComponent } from './admin/admin-analysis/admin-analysis.component';

import { ParticipantEventComponent } from './participant/participant-event/participant-event.component';
import { ParticipantProfileComponent } from './participant/participant-profile/participant-profile.component';
import { ParticipantSettingsComponent } from './participant/participant-settings/participant-settings.component';

import { WebsiteAdvertisementComponent } from './website/website-advertisement/website-advertisement.component';
import { WebsiteFAQComponent } from './website/website-faq/website-faq.component';
import { WebsiteAboutusComponent } from './website/website-aboutus/website-aboutus.component';
import { WebsiteEventComponent } from './website/website-event/website-event.component';
import { ApprovalOrganizationDetailsComponent } from './admin/admin-organizers-approvals/more-details/approval-organization-details/approval-organization-details.component';
import { ApprovalEventDetailsComponent } from './admin/admin-organizers-approvals/more-details/approval-event-details/approval-event-details.component';
import { ApprovalPaymentDetailsComponent } from './admin/admin-organizers-approvals/more-details/approval-payment-details/approval-payment-details.component';
import { EventRegistrationComponent } from './website/website-event/event-registration/event-registration.component';
import { EventGroupComponent } from './website/website-event/event-group/event-group.component';
import { EventCreateGroupComponent } from './website/website-event/event-create-group/event-create-group.component';
import { EventJoinGroupComponent } from './website/website-event/event-join-group/event-join-group.component';
import {ComingSoonPageComponent} from './website/coming-soon-page/coming-soon-page.component';


const routes: Routes = [
  {
    //change this line when go live.
    path: '',
    component: ComingSoonPageComponent,
  },
  {
    //added temporary path. remove it when go live.
    path: 'app',
    component: WebsiteMainComponent,
    children: [
      {
        path: '',
        component: WebsiteAdvertisementComponent,
      },
      {
        path: 'FAQ',
        component: WebsiteFAQComponent,
      },
      {
        path: 'about-us',
        component: WebsiteAboutusComponent,
      },
      {
        path: 'event',
        component: WebsiteEventComponent,
        children: [
          {
            path: 'individual',
            component: EventRegistrationComponent,
          },
          {
            path: 'group',
            component: EventGroupComponent,
          },
          {
            path: 'create-group',
            component: EventCreateGroupComponent,
          },
          {
            path: 'join-group',
            component: EventJoinGroupComponent,
          }
        ]
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'sign-up',
    component: SignUpTypeHomeComponent,
    children: [
      {
        path: '',
        component: SignUpTypeComponent,
      },
      {
        path: 'organization',
        component: SignUpTypeOrganizerComponent,
      },
      {
        path: 'participant',
        component: SignUpTypeParticipantComponent,
      },
    ],
  },
  {
    path: 'd',
    component: DashboardHomeComponent,

    children: [
      {
        path: 'organizer',
        component: OrganizerMainComponent,
        children: [
          {
            path: '',
            component: OrganizerProfileComponent,
          },
          {
            path: 'organizer-event',
            component: OrganizerEventComponent,
            children: [
              {
                path: 'event-organizer-details',
                component: EventOrganizationDetailsComponent,
              },
              {
                path: 'event-details',
                component: EventDetailsComponent,
              },
              {
                path: 'event-payment-details',
                component: EventPaymentDetailsComponent,
              },
            ],
          },
          {
            path: 'organizer-event-applications',
            component: OrganizerEventApplicationsComponent,
          },
          {
            path: 'organizer-event-createnew-event',
            component: OrganizerEventCreatenewEventComponent,
          },
          {
            path: 'organizer-event-createnew-payment',
            component: OrganizerEventCreatenewPaymentComponent,
          },
          {
            path: 'organizer-payment',
            component: OrganizerPaymentComponent,
          },
          {
            path: 'organizer-inquiries',
            component: InquiryListComponent,
          },
          {
            path: 'inquiries-createnew',
            component: CreateInquiryComponent,
          },
        ],
      },
      {
        path: 'admin',
        component: AdminMainComponent,
        children: [
          {
            path: '',
            component: AdminProfileComponent,
          },
          {
            path: 'admin-organizers-search',
            component: AdminOrganizersSearchComponent,
          },
          {
            path: 'admin-organizers-createnew',
            component: AdminOrganizersCreatenewComponent,
          },
          {
            path: 'admin-organizers-approvals',
            component: AdminOrganizersApprovalsComponent,
            children: [
              {
                path: 'organizer-details',
                component: ApprovalOrganizationDetailsComponent,
              },
              {
                path: 'event-details',
                component: ApprovalEventDetailsComponent,
              },
              {
                path: 'payment-details',
                component: ApprovalPaymentDetailsComponent,
              },
            ],
          },
          {
            path: 'admin-participants-search',
            component: AdminParticipantsSearchComponent,
          },
          {
            path: 'admin-participants-createnew',
            component: AdminParticipantsCreatenewComponent,
          },
          {
            path: 'organization-inquiries',
            component: AdminInquiriesComponent,
          },
          {
            path: 'admin-analysis',
            component: AdminAnalysisComponent,
          },
          {
            path: 'admin-settings',
            component: AdminSettingsComponent,
          },
        ],
      },
      {
        path: 'participant',
        component: ParticipantMainComponent,
        children: [
          {
            path: '',
            component: ParticipantProfileComponent,
          },
          {
            path: 'participant-event',
            component: ParticipantEventComponent,
          },
          {
            path: 'participant-settings',
            component: ParticipantSettingsComponent,
          },
          {
            path: 'participant-inquiries',
            component: InquiryListComponent,
          },
          {
            path: 'inquiries-createnew',
            component: CreateInquiryComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
