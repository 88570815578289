<div class="Inquiries-main">
    
    <div class="grid-one">
        <form #form="ngForm">
            <span class="ln-1">
                <label style="display:block;margin-bottom:4px">Subject to</label>
                <p-dropdown [options]="subject" name="subject" ngModel placeholder="Select"
                            optionLabel="name" [showClear]="true"></p-dropdown>
            </span><br>
        </form>

        <span class="header" style="margin-top: 10px;">Contact Information</span>
        <form #form="ngForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Designation</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Email</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Contact No</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
        </form>
    </div>

    <div class="grid-two">
        <form #form="ngForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Message</label>
                <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
            <div style="position: absolute;right:0;bottom:0;">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Cancel"></button>
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Submit"></button>
            </div>
            
        </form>
    </div>

</div>
