<div class="createnew-main">

  <div class="grid-one">

    <div class="search">
            <span class="p-float-label" style="width: 85%;">
                <input id="float-input" style="width: 98%;" type="text" pInputText>
                <label for="float-input">Enter Organization Name or Email</label>
            </span>
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="width: 15%;"
              label="Search"></button>
    </div>

    <span class="header">Organization Details</span>
    <form #form="ngForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Organization Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Reg. No (If any)</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Contact No</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Email Address</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Organization Type</label>
                <p-dropdown [options]="OrganizationType" name="OrganizationType" ngModel placeholder="Select"
                            optionLabel="name" [showClear]="true"></p-dropdown>
            </span><br>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Address</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">About (Optional)</label>
                <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
    </form>
  </div>

  <div class="grid-two">
    <span class="header">Other Details</span>
    <form #form="ngForm">
            <span class="ln-1">
                <label for="eventcount" style="display:block;margin-bottom:4px">How many events are you planning to host
                    per mounth</label>
                <input type="text" id="eventcount" name="eventcount" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="peoplecount" style="display:block;margin-bottom:4px">How many people do you expect per
                    event</label>
                <input type="text" id="peoplecount" name="peoplecount" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="budget" style="display:block;margin-bottom:4px">How much would you pay hosting your
                    event</label>
                <input type="text" id="budget" name="budget" ngModel pInputText placeholder="">
            </span>
    </form>

    <span class="header" style="margin-top: 10px;">Representative's Information</span>
    <form #form="ngForm">
            <span class="ln-1">
                <label for="Name" style="display:block;margin-bottom:4px">Name</label>
                <input type="text" id="Name" name="Name" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="Designation" style="display:block;margin-bottom:4px">Designation</label>
                <input type="text" id="Designation" name="Designation" ngModel pInputText placeholder="">
            </span>
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="width: 50%;margin: auto;margin-top: 20px;"
              label="Create"></button>
    </form>
  </div>


</div>
