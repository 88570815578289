import {Injectable} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {NotificationsService} from 'angular2-notifications';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Observable} from 'rxjs';
import {DataServiceService} from './data-service.service';
import {OrganizerEventService} from '../../organizer/organizer-event/organizer-event.service';

@Injectable({
  providedIn: 'root'
})
export class FireService {
  timestamp = new Date();

  constructor(
    private firestore: AngularFirestore,
    private notification: NotificationsService,
    private storage: AngularFireStorage,
    private dataService: DataServiceService,
    private eventService: OrganizerEventService
  ) {
  }

  uploadBanners = (event: any) => {
    let uploadPercent: Observable<number>;
    let downloadURL: Observable<string>;
    const file = event.target.files[0];
    const path = 1000000000000000 - this.timestamp.getTime();
    const filePath = 'eventBanners/' + (this.timestamp.getMonth().toString() + this.timestamp.getFullYear().toString()) + '/' + path;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task.percentageChanges().subscribe(res => {
      // console.log(uploadPercent.subscribe());
      // console.log(res);
      if (res === 100) {
        // Todo: Notify that upload was finished.
      }
    });
    task.snapshotChanges().pipe(
      finalize(() => {
        downloadURL = fileRef.getDownloadURL();
        downloadURL.subscribe(url => {
          this.eventService.createNewEvent.eventBanner = url;
        });
      })
    ).subscribe();
    // get notified when the download URL is available

  };
}
