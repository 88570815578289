import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizer-payment',
  templateUrl: './organizer-payment.component.html',
  styleUrls: ['./organizer-payment.component.scss']
})
export class OrganizerPaymentComponent implements OnInit {

  events = [
    {
      name: 'Google IO',
      code: 'GIO'
    },
    {
      name: 'IEEE Meetup',
      code: 'IEEE'
    }
  ];

  paymentType : any;

  constructor() { }

  ngOnInit(): void {
  }

}
