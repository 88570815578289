import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-inquiry',
  templateUrl: './create-inquiry.component.html',
  styleUrls: ['./create-inquiry.component.scss']
})
export class CreateInquiryComponent implements OnInit {

  subject = [
    {
      name: 'General/ Event/ Payment so on',
      code: 'PAY'
    },
    {
      name: 'General/ Event/ New',
      code: 'NEW'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
