<!--
   * This is the Admin's Dashboard.
   * Please do any kind of implementation regards to the admin dashboard under the admin folder.
   * If you have any clarifications feel free to contact me (Lakshan)
 -->
<!--
    ⚠ we need to change this folder name as Admin. let's change the name after developments are done. until, you can countinue your works as usual.
 -->


<!-- <app-dashboard-home></app-dashboard-home> -->
<router-outlet></router-outlet>
