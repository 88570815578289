<div class="createnew-main">

  <div class="grid-one">
    <span class="header">Payments</span>
    <form #form="ngForm">
            <span class="ln-2">
                <p-radioButton name="payment" value="paynow" [(ngModel)]="payment" inputId="payment1"></p-radioButton>
                <label for="payment1"> Pay Now </label>
            </span>
      <span class="ln-2">
                <p-radioButton name="payment" value="paylater" [(ngModel)]="payment" inputId="payment2"></p-radioButton>
                <label for="payment2"> Pay Later </label>
            </span>
    </form>
    <span class="header" style="margin-top: 10px;">Payment Method</span>
    <form #form="ngForm">
            <span class="ln-2">
                <p-radioButton name="payment" value="card" [(ngModel)]="paymentType" inputId="payment1"></p-radioButton>
                <label for="payment1"> Card Payment </label>
            </span>
      <span class="ln-2">
                <p-radioButton name="payment" value="bank" [(ngModel)]="paymentType" inputId="payment2"></p-radioButton>
                <label for="payment2"> Bank Deposit </label>
            </span>
      <span class="ln-2">
                <p-radioButton name="payment" value="wire" [(ngModel)]="paymentType" inputId="payment3"></p-radioButton>
                <label for="payment3"> Wire Tranfer </label>
            </span>
      <span class="ln-2">
                <p-radioButton name="payment" value="online" [(ngModel)]="paymentType"
                               inputId="payment4"></p-radioButton>
                <label for="payment4"> Online Transfer </label>
            </span>

    </form>

    <span class="header" style="margin-top: 10px;">Payment Details</span>
    <form #form="ngForm">
            <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Receipt No</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Amount (LKR)</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>

      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Deposit/ Transfer Date</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Bank Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>

      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Receipt Image</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
    </form>
  </div>

  <div class="grid-two">
    <form #form="ngForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Notes to SLEMS (if any)</label>
                <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
      <div style="position: absolute;right:0;bottom:0;">
<!--        <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Back"-->
<!--                (click)="clickBackButton()"></button>-->
        <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Submit" (click)="clickSubmitButton()"></button>
      </div>

    </form>
  </div>

</div>
