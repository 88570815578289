<header>

    <div class="item">
        <span class="header-logo" routerLink="/">RSVP.LK</span>
    </div>

    <div class="item" style="float: right;">
        <div class="header-button">
            <button *ngIf="!dataService.IS_USER_LOGGED_IN" pButton type="button" routerLink="/login" >Sign In</button>
            <button *ngIf="dataService.IS_USER_LOGGED_IN" pButton type="button" routerLink="/d/participant" >
              {{dataService?.LOGGED_USER_DATA?.firstName? dataService.LOGGED_USER_DATA.firstName : ''}}
              {{dataService?.LOGGED_USER_DATA?.lastName? dataService.LOGGED_USER_DATA.lastName : ''}}
            </button>
        </div>

    </div>

</header>
