<div class="application-main">

  <div class="header">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Search Applicant" />
        </span>
    <div class="header-counts">
      <span>Total Applicants : 10,547</span>
      <span>Accepted : 10,000</span>
      <span>Rejected : 47</span>
      <span>Pending : 500</span>
    </div>
  </div>

  <p-table [value]="applications" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [rowsPerPageOptions]="[10,25,50]">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 18%;">
          Applicant No
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Name
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          NIC
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Address
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          T-shirt
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th>
          Action
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
        <th style="width: 10%;">More</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-application>
      <tr>
        <td>{{application.id}}</td>
        <td>{{application.name}}</td>
        <td>{{application.NIC}}</td>
        <td>{{application.address}}</td>
        <td>{{application.tshirt}}</td>
        <td><span [class]="'status status-' + application.status">{{application.status}}</span></td>
        <td><span class="more-span pi pi-ellipsis-v" ></span></td>
      </tr>
    </ng-template>
  </p-table>
</div>

