<div class="faq-main">

    <div class="faq-img">
        <div class="faq-header">Frequently Asked Questions</div>
    </div>

    <p-accordion>
        <p-accordionTab *ngFor="let data of FAQs;"  header="{{data.question}}" >
            <p>{{data.answer}}</p>
        </p-accordionTab>
    </p-accordion>



</div>
