import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';
import jwt_decode from 'jwt-decode';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';


export interface Event {
  id: any;
  eventName: string;
  description: string;
  date: any;
  time: any;
  status: string;
  event: any;
}

@Component({
  selector: 'app-participant-event',
  templateUrl: './participant-event.component.html',
  styleUrls: ['./participant-event.component.scss']
})
export class ParticipantEventComponent implements OnInit {

  loggedUserToken: any;
  first = 0;
  rows = 10;

  events: Event[] = [
    {'id': '0', 'eventName': 'Google IO', 'description': 'ABC Event', 'date': '02.02.2021', 'time': '6.30pm', 'status': 'NOT-SELECTED', 'event':''},
    {'id': '1', 'eventName': 'IEEE Meetup', 'description': 'John Event', 'date': '02.02.2021', 'time': '6.30pm', 'status': 'SELECTED', 'event':''},
    {'id': '2', 'eventName': 'IEEE Meetup', 'description': 'Albert Event', 'date': '02.02.2021', 'time': '6.30pm', 'status': 'PENDING', 'event':''}
  ];

  constructor(
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    const decoded: any = jwt_decode(this.loggedUserToken);
    const loggedUserEvents = JSON.parse(JSON.stringify(sessionStorage.getItem('loggedUserEvents')));

    if (!loggedUserEvents) {
      this.loadEventData(decoded);
    } else {
      this.setDataToGrid(JSON.parse(loggedUserEvents));
    }
  }

  loadEventData = (user: any) => {
    const path = this.dataService.BASE_PATH + this.dataService.EVENT_REGISTRATION_SEARCH;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    const searchCriteria = {participantEmail: user.data.email};
    this.http.post(path, searchCriteria, header).toPromise().then(res => {
      const result = JSON.parse(JSON.stringify(res));

      sessionStorage.setItem('loggedUserEvents', JSON.stringify(result.data));
      if (result.data) {
        this.setDataToGrid(result.data);
      }

    }).catch(e => {
      // ToDo: need to add notification
      console.log(e);
    });
  };

  setDataToGrid = (data: any) => {
    this.events = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      // @ts-ignore
      const tempEvent = {
        'id': (i + 1),
        'eventName': data[i].eventId.eventName,
        'description': data[i].eventId.description,
        'date': this.datePipe.transform(data[i].eventId.startDateTime, 'MM-dd-yyyy'),
        'time': this.datePipe.transform(data[i].eventId.startDateTime, 'hh.mm a'),
        'status': data[i].status.toUpperCase(),
        'event': data[i].eventId
      };
      this.events.push(tempEvent);
    }
  };

  viewEvent = (event: any) => {
    sessionStorage.setItem('selectedEvent', JSON.stringify(event));
    // this.router.navigateByUrl('/event');
    window.open('/event');
  }
}
