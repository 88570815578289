<div class="stepsdemo-content">
    <div class="main">

        <div class="grid-one">
            <span class="header">Event Details</span>
            <form [formGroup]="eventDetailsForm">
                <span class="ln-1">
                    <label for="eventTitle" style="display:block;margin-bottom:4px">Event Title</label>
                    <input type="text" id="eventTitle" formControlName="eventTitle" readonly pInputText placeholder="">
                </span>

                <span class="ln-2">
                    <label for="registrationDeadline" style="display:block;margin-bottom:4px">Registration Deadline</label>
                    <input type="text" id="registrationDeadline" formControlName="registrationDeadline" readonly pInputText placeholder="">
                </span>
                <span class="ln-2">
                    <label for="eventDate" style="display:block;margin-bottom:4px">Event Date</label>
                    <input type="text" id="eventDate" formControlName="eventDate" readonly pInputText placeholder="">
                </span>

                <span class="ln-2">
                    <label for="email" style="display:block;margin-bottom:4px">Email Address</label>
                    <input type="text" id="email" formControlName="email" readonly pInputText placeholder="">
                </span>

                <span class="ln-2">
                    <label style="display:block;margin-bottom:4px">Targeted Group</label>
                    <p-multiSelect [options]="OrganizationType" defaultLabel="Select" optionLabel="name" display="chip">
                    </p-multiSelect>
                </span>

                <span class="ln-1">
                    <label for="eventLocationLink" style="display:block;margin-bottom:4px">event Location Link</label>
                    <input type="text" id="eventLocationLink" formControlName="eventLocationLink" readonly pInputText placeholder="">
                </span>
                <span class="ln-1">
                    <label for="eventDescription" style="display:block;margin-bottom:4px">Event Description</label>
                    <textarea type="text" id="eventDescription" formControlName="eventDescription" readonly pInputText placeholder=""></textarea>
                </span>
            </form>
        </div>

        <div class="grid-two">
            <span class="header">Requested Participant's Details</span>
          <ul>
            <li *ngFor="let data of organizerEventService.currentEventMore.formRequestedDetails;">{{data}}</li>
          </ul>
<!--            <form #form="ngForm">-->
<!--                <span class="ln-1" *ngFor="let pdetail of participentDetails">-->
<!--                    <p-checkbox name="group1" value="{{pdetail.name}}" inputId="ny"></p-checkbox>-->
<!--                    <label for="binary"> {{pdetail.name}} </label>-->
<!--                </span>-->
<!--            </form>-->
        </div>
    </div>
    <div class="p-grid p-nogutter p-justify-end" >
        <p-button label="Back" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
        <p-button class="next" label="Next" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
    </div>

</div>
