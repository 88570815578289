<div class="participant-main">
  <div class="container-one">
    <emp-logo></emp-logo>
    <span>Organization Details
            <hr>
        </span>
    <form #form="ngForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Organization Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Reg. No (If any)</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Contact No</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="firstName" style="display:block;margin-bottom:4px">Email Address</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Organization Type</label>
                <p-dropdown [options]="OrganizationType" name="OrganizationType" ngModel placeholder="Select" optionLabel="name"
                            [showClear]="true"></p-dropdown>
            </span><br>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Address</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">About (Optional)</label>
                <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
    </form>
  </div>
  <div class="container-two">
        <span>Your Details
            <hr>
        </span>
    <form>
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">First Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Last Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">NIC No</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Email Address</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span>
                <label style="display:block;margin-bottom:4px">Gender</label>
                <p-dropdown [options]="gender" name="gender" ngModel placeholder="Select" optionLabel="name"
                            [showClear]="true"></p-dropdown>
            </span><br>
      <span class="conditions">
                By clicking Sign Up, you agree to our Terms and that you have read our Data Use Policy, including our
                Cookie Use."
            </span> <br>
    </form>
    <button pButton pRipple type="button" label="Sign up" class="p-button-outlined p-button-secondary"></button>
  </div>
</div>
