<div class="event-main">

    <div class="event-img">
        <img alt="Card" class="image" [src]="event.eventBannerOne ? event.eventBannerOne : ''">
    </div>

    <div class="event-description">
        <div class="grid-one">
            <div class="event-title">{{event.eventName ? event.eventName : ''}}</div>
            <div id="organization" class="event-details">{{event.organizedBy ? event.organizedBy : ''}}</div>
        </div>
        <div class="grid-two">
            <div id="date" class="event-details">From: {{(event.startDateTime ? event.startDateTime : '')  | date: 'MMM d, y, h:mm a'}}</div>
            <div id="time" class="event-details">To: {{(event.endDateTime ? event.endDateTime : '')  | date: 'MMM d, y, h:mm a'}}</div>
          <div id="venue" class="event-details">
            {{event.addressLineOne ? event.addressLineOne : ""}}
            {{event.addressLineTwo ? ", " + event.addressLineTwo : ""}}
            {{event.city ? ", " + event.city : ""}}
          </div>
        </div>
    </div>

    <div class="event-body">
        <div class="grid-one" [innerHTML]="event.description ? event.description : ''">

<!--            <p>The problem with most About Us pages is that they’re an afterthought—a link buried at the bottom of the-->
<!--                page that leads to a few hastily written paragraphs about a company.</p>-->
<!--            <p>What an About Us page should be is a goal-oriented sales page, one that focuses on highlighting the-->
<!--                biggest selling points of your story and brand, making a strong first impression on curious customers-->
<!--                In Shopify’s customer trust research, we found that shoppers navigate to an About Us page to learn more-->
<!--                about the brand and the people behind the products. Your About Page should address those two curiosities-->
<!--                shoppers have to help with decision making.</p>-->
<!--            <p>Shoppers are also interested in a company’s mission. They’ll use the About Us page to determine if they-->
<!--                share core values with the business and to decide if they want to shop with the business or not. </p>-->


<!--            <h4>Agenda</h4>-->
<!--            <div>09.00am : Google Cloud</div>-->
<!--            <div>12.30pm : Break</div>-->
<!--            <div>01.00pm : Into the docker</div>-->
<!--            <div>02.00pm : Event end</div>-->

        </div>
      <div class="grid-two">
        <h3>Event Registration</h3>
        <span *ngIf="dataService.IS_USER_LOGGED_IN">Following details are shared with the event organizer when you register.</span>
        <ul *ngIf="dataService.IS_USER_LOGGED_IN">
          <li *ngFor="let requestedDetails of event.formRequestedDetails">{{requestedDetails}}</li>
        </ul>
        <app-event-internal-login *ngIf="!dataService.IS_USER_LOGGED_IN"></app-event-internal-login>
        <p-button class="register-btn" *ngIf="dataService.IS_USER_LOGGED_IN" (click)="register()" >
          <label *ngIf="!isSubmitting">Register</label>
          <i *ngIf="isSubmitting && !isLoading" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          <i *ngIf="isDone && isSubmitting && isLoading" class="pi pi-check" style="font-size: 2rem"></i>
        </p-button>
        <!--            <router-outlet></router-outlet>-->
        <!-- <form #form="ngForm">
            <header>Register</header>

            <span class="ln-1">
                <label for="firstName">Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="lastName">Address</label>
                <input type="text" id="lastName" name="lastName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="lastName">Email</label>
                <input type="email" id="lastName" name="lastName" ngModel pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="lastName">Contact No</label>
                <input type="email" id="lastName" name="lastName" ngModel pInputText placeholder="">
            </span>

            <span style='padding-top: 20px;width: 100%;'>
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary"
                    label="Register"></button>
            </span>

        </form> -->
        </div>
    </div>
</div>
