import {Component, OnInit} from '@angular/core';
import {DashboardModel} from '../../common/models/dashboard-model';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.scss']
})
export class AdminMainComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
