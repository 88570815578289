<div class="approval-main">
  <div class="header">Event Requests</div>

  <p-table
    [value]="events"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 15%">
           Request Date
          <p-columnFilter
            type="text"
            field="name"
            display="menu"
          ></p-columnFilter>
        </th>
        <th>
          Organization
          <p-columnFilter
            type="text"
            field="name"
            display="menu"
          ></p-columnFilter>
        </th>
        <th>
          Event Title
          <p-columnFilter
            type="text"
            field="name"
            display="menu"
          ></p-columnFilter>
        </th>
        <th>
          Event Date
          <p-columnFilter
            type="text"
            field="date"
            display="menu"
          ></p-columnFilter>
        </th>
        <th>
          Status
          <p-columnFilter
            type="text"
            field="name"
            display="menu"
          ></p-columnFilter>
        </th>
        <th style="width: 10%">More</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr>
        <td>{{ event.requestSubmittedDate?? '' | date: 'short' }}</td>
        <td>
          {{ event.organizedBy ?? '' }}<br /><span class="l-2"
            >{{ event?.organizerId?.organizationType? event.organizerId.organizationType : '' }}</span
          >
        </td>
        <td>{{ event.eventName?? '' }}</td>
        <td>
          {{ event.startDateTime | date: 'short'}}<br /><span class="l-2">{{ event.endDateTime | date: 'short'}}</span>
        </td>
        <td>
          <span [class]="'status status-' + event.status">{{
            event.status
          }}</span>
        </td>
        <td>
          <span (click)="show(event)" class="pi pi-ellipsis-v"></span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
