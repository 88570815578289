import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AdminEventService} from '../../admin-event.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-approval-event-details',
  templateUrl: './approval-event-details.component.html',
  styleUrls: ['./approval-event-details.component.scss'],
  providers: [DatePipe]
})
export class ApprovalEventDetailsComponent implements OnInit {

  eventDetailForm = new FormGroup({
    title: new FormControl(),
    registrationDeadline: new FormControl(),
    eventDate: new FormControl(),
    email: new FormControl(),
    eventLocationLink: new FormControl(),
    eventDescription: new FormControl()
  });

  targetGroup: any;
  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  participentDetails = [
    // {id: 1, name: 'Name'},
    // {id: 2, name: 'School/Organization Name'},
    // {id: 5, name: 'Email'},
    // {id: 3, name: 'Contact No'},
    // {id: 4, name: 'Tshirt Size'},
    // {id: 4, name: 'Gender'},
    // {id: 4, name: 'Food Preference'}
  ];

  constructor(
    private router: Router,
    public adminEventService: AdminEventService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.targetGroup = this.adminEventService.currentEvent.targetGroup;
    this.participentDetails = this.adminEventService.currentEvent.formRequestedDetails;
    this.eventDetailForm = this.fb.group({
      title: [this.adminEventService.currentEvent.eventName],
      registrationDeadline: [this.datePipe.transform(this.adminEventService.currentEvent.registrationDeadline, 'short')],
      eventDate: [this.datePipe.transform(this.adminEventService.currentEvent.startDateTime, 'short')],
      email: [this.adminEventService.currentEvent.organizerId.email],
      eventLocationLink: [this.adminEventService.currentEvent.eventLocationLink],
      eventDescription: [this.adminEventService.currentEvent.description]
    });
  }

  nextPage() {
    this.router.navigate([
      'd/admin/admin-organizers-approvals/payment-details',
    ]);
  }

  prevPage() {
    this.router.navigate([
      'd/admin/admin-organizers-approvals/organizer-details',
    ]);
  }
}
