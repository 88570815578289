<div class="createnew-main">
  <div class="contain-one">
  </div>
  <div class="contain-two">
    <div class="grid-one">
      <span class="header">Event Details</span>
      <form [formGroup]="eventDetailsForm">
                <span class="ln-1">
                    <label
                      for="eventTitle" style="display:block;margin-bottom:4px">Event Title</label>
                    <input type="text" id="eventTitle" formControlName="eventTitle" pInputText placeholder="">
                </span>

        <span class="ln-2">
                    <label for="registrationDeadline"
                           style="display:block;margin-bottom:4px">Registration Deadline</label>
               <p-calendar
                 [showTime]="true"
                 id="registrationDeadline"
                 formControlName="registrationDeadline"
                 inputId="time">
               </p-calendar>
                 </span>
        <span class="ln-2">
                    <label for="eventDate" style="display:block;margin-bottom:4px">Event Date (or date range)</label>
                  <p-calendar
                    selectionMode="range"
                    formControlName="eventDate"
                    [readonlyInput]="true"
                    id="eventDate"
                    inputId="range">
                  </p-calendar>
        </span>

        <span class="ln-2">
                    <label for="email" style="display:block;margin-bottom:4px">Email Address</label>
                    <input type="text" id="email" formControlName="email" pInputText placeholder="">
                </span>

        <span class="ln-2">
                    <label style="display:block;margin-bottom:4px">Targeted Group</label>
                    <p-multiSelect [options]="OrganizationType" defaultLabel="Select" formControlName="targetGroup"
                                   optionLabel="name" display="chip">
                    </p-multiSelect>
                </span>

        <span class="ln-2">
                    <label for="eventLocationLink" style="display:block;margin-bottom:4px">event Location Link</label>
                    <input type="text" id="eventLocationLink" formControlName="eventLocationLink" pInputText
                           placeholder="">
                </span>
        <span class="ln-2">
                    <label for="eventBanner" style="display:block;margin-bottom:4px">Event Banner</label>
                    <input type="file" id="eventBanner" (change)="uploadBanner($event)" pInputText placeholder="">
                </span>
      </form>
      <span class="header" style="margin-top: 10px;">Social Media Details ( if any )</span>
      <form [formGroup]="socialMediaDetailsForm">
                <span class="ln-2">
                    <label for="facebookUrl" style="display:block;margin-bottom:4px">Facebook</label>
                    <input type="text" id="facebookUrl" formControlName="facebookUrl" pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="twitterUrl" style="display:block;margin-bottom:4px">Twitter</label>
                    <input type="text" id="twitterUrl" formControlName="twitterUrl" pInputText placeholder="">
                </span>

        <span class="ln-2">
                    <label for="linkedinUrl" style="display:block;margin-bottom:4px">Linkedin</label>
                    <input type="text" id="linkedinUrl" formControlName="linkedinUrl" pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="websiteUrl" style="display:block;margin-bottom:4px">Website</label>
                    <input type="text" id="websiteUrl" formControlName="websiteUrl" pInputText placeholder="">
                </span>

      </form>

      <span class="header" style="margin-top: 10px;">Requested Participant's Details</span>
      <form [formGroup]="requestedParticipantsDetailsForm">
        <span class="ln-2" *ngFor="let pdetail of participentDetails">
                    <p-checkbox name="group1" value="{{pdetail.name}}"
                                [formControl]="$any(requestedParticipantsDetailsForm).controls['requirements']"
                                inputId="ny"></p-checkbox>
                    <label> {{pdetail.name}} </label>
                </span>
      </form>

    </div>

    <div class="grid-two">
      <span class="header" style="margin-top: 10px;">Organizer Details</span>
      <form [formGroup]="organizerDetailsForm">
                <span class="ln-1">
                    <label for="organizedBy" style="display:block;margin-bottom:4px">Organized By</label>
                    <input type="text" id="organizedBy" formControlName="organizedBy" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="addressLineOne" style="display:block;margin-bottom:4px">Address Line 1</label>
                    <input type="text" id="addressLineOne" formControlName="addressLineOne" pInputText placeholder="">
                </span>

        <span class="ln-1">
                    <label for="addressLineTwo" style="display:block;margin-bottom:4px">Address Line 2</label>
                    <input type="text" id="addressLineTwo" formControlName="addressLineTwo" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="city" style="display:block;margin-bottom:4px">City</label>
                    <input type="text" id="city" formControlName="city" pInputText placeholder="">
                </span>

      </form>

      <form [formGroup]="eventDescriptionForm">
                <span class="ln-1">
                    <label for="description" style="display:block;margin-bottom:4px">Event Description</label>
                    <textarea type="text" id="description" formControlName="description" pInputText
                              placeholder=""></textarea>
                </span>
        <button pButton pRipple type="button" (click)="btnClick();" class="p-button-outlined p-button-secondary"
        ><label *ngIf="!isSubmitting">Submit</label>
          <i *ngIf="isSubmitting && !isLoading" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          <i *ngIf="isDone && isSubmitting && isLoading" class="pi pi-check" style="font-size: 2rem"></i>
        </button>
      </form>
    </div>
  </div>

</div>
