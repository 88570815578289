import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//PrimeNG Modules
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { KnobModule } from 'primeng/knob';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DataViewModule } from 'primeng/dataview';
import { GMapModule } from 'primeng/gmap';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { PaginatorModule } from 'primeng/paginator';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { SplitterModule } from 'primeng/splitter';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ChartModule } from 'primeng/chart';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { DragDropModule } from 'primeng/dragdrop';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { CaptchaModule } from 'primeng/captcha';
import { ChipModule } from 'primeng/chip';
import { InplaceModule } from 'primeng/inplace';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SkeletonModule } from 'primeng/skeleton';
import { TerminalModule } from 'primeng/terminal';
import { TagModule } from 'primeng/tag';
import { DeferModule } from 'primeng/defer';
import { FocusTrapModule } from 'primeng/focustrap';
import { RippleModule } from 'primeng/ripple';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Components
import { ParticipantMainComponent } from './participant/participant-main/participant-main.component';
import { WebsiteMainComponent } from './website/website-main/website-main.component';
import { LoginComponent } from './common/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { LogoComponent } from './common/logo/logo.component';
import { SignUpTypeComponent } from './common/sign-up-type/sign-up-type.component';
import { SignUpTypeParticipantComponent } from './common/sign-up-type/sign-up-type-participant/sign-up-type-participant.component';
import { SignUpTypeOrganizerComponent } from './common/sign-up-type/sign-up-type-organizer/sign-up-type-organizer.component';
import { SignUpTypeHomeComponent } from './common/sign-up-type/sign-up-type-home/sign-up-type-home.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { AdminInquiriesComponent } from './admin/admin-inquiries/admin-inquiries.component';
import { AdminAnalysisComponent } from './admin/admin-analysis/admin-analysis.component';
import { AdminSettingsComponent } from './admin/admin-settings/admin-settings.component';
import { DashboardHomeComponent } from './common/dashboard-home/dashboard-home.component';
import { AdminOrganizersSearchComponent } from './admin/admin-organizers-search/admin-organizers-search.component';
import { AdminOrganizersCreatenewComponent } from './admin/admin-organizers-createnew/admin-organizers-createnew.component';
import { AdminOrganizersApprovalsComponent } from './admin/admin-organizers-approvals/admin-organizers-approvals.component';
import { AdminParticipantsSearchComponent } from './admin/admin-participants-search/admin-participants-search.component';
import { AdminParticipantsCreatenewComponent } from './admin/admin-participants-createnew/admin-participants-createnew.component';
import { InquiryDetailsComponent } from './admin/admin-inquiries/inquiry-details/inquiry-details.component';
import { AdminMainComponent } from './admin/admin-main/admin-main.component';
import { OrganizerMainComponent } from './organizer/organizer-main/organizer-main.component';
import { OrganizerProfileComponent } from './organizer/organizer-profile/organizer-profile.component';
import { OrganizerEventComponent } from './organizer/organizer-event/organizer-event.component';
import { OrganizerEventApplicationsComponent } from './organizer/organizer-event/organizer-event-applications/organizer-event-applications.component';
import { OrganizerEventCreatenewEventComponent } from './organizer/organizer-event/organizer-event-createnew-event/organizer-event-createnew-event.component';
import { OrganizerEventCreatenewPaymentComponent } from './organizer/organizer-event/organizer-event-createnew-payment/organizer-event-createnew-payment.component';
import { OrganizerEventDetailsComponent } from './organizer/organizer-event/organizer-event-details/organizer-event-details.component';
import { EventOrganizationDetailsComponent } from './organizer/organizer-event/organizer-event-details/event-organization-details/event-organization-details.component';
import { EventDetailsComponent } from './organizer/organizer-event/organizer-event-details/event-details/event-details.component';
import { EventPaymentDetailsComponent } from './organizer/organizer-event/organizer-event-details/event-payment-details/event-payment-details.component';
import { OrganizerPaymentComponent } from './organizer/organizer-payment/organizer-payment.component';
import { ParticipantProfileComponent } from './participant/participant-profile/participant-profile.component';
import { ParticipantEventComponent } from './participant/participant-event/participant-event.component';
import { ParticipantSettingsComponent } from './participant/participant-settings/participant-settings.component';
import { InquiryListComponent } from './common/inquiry-list/inquiry-list.component';
import { CreateInquiryComponent } from './common/create-inquiry/create-inquiry.component';
import { WebsiteHeaderComponent } from './website/website-header/website-header.component';
import { WebsiteAdvertisementComponent } from './website/website-advertisement/website-advertisement.component';
import { WebsiteFooterComponent } from './website/website-footer/website-footer.component';
import { WebsiteFAQComponent } from './website/website-faq/website-faq.component';
import { WebsiteAboutusComponent } from './website/website-aboutus/website-aboutus.component';
import { WebsiteEventComponent } from './website/website-event/website-event.component';
import {HttpClientModule} from '@angular/common/http';
import {SimpleNotificationsModule} from 'angular2-notifications';
import { MoreDetailsComponent } from './admin/admin-organizers-approvals/more-details/more-details.component';
import { ApprovalEventDetailsComponent } from './admin/admin-organizers-approvals/more-details/approval-event-details/approval-event-details.component';
import { ApprovalOrganizationDetailsComponent } from './admin/admin-organizers-approvals/more-details/approval-organization-details/approval-organization-details.component';
import { ApprovalPaymentDetailsComponent } from './admin/admin-organizers-approvals/more-details/approval-payment-details/approval-payment-details.component';
import { EventRegistrationComponent } from './website/website-event/event-registration/event-registration.component';
import { EventGroupComponent } from './website/website-event/event-group/event-group.component';
import { EventCreateGroupComponent } from './website/website-event/event-create-group/event-create-group.component';
import { EventJoinGroupComponent } from './website/website-event/event-join-group/event-join-group.component';
import {ConfirmationService} from 'primeng/api';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import { ComingSoonPageComponent } from './website/coming-soon-page/coming-soon-page.component';
import {EventInternalLoginComponent} from './common/event-internal-login/event-internal-login.component';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ParticipantMainComponent,
    WebsiteMainComponent,
    LoginComponent,
    ResetPasswordComponent,
    LogoComponent,
    SignUpTypeComponent,
    SignUpTypeParticipantComponent,
    SignUpTypeOrganizerComponent,
    SignUpTypeHomeComponent,
    AdminProfileComponent,
    AdminInquiriesComponent,
    AdminAnalysisComponent,
    AdminSettingsComponent,
    DashboardHomeComponent,
    AdminOrganizersSearchComponent,
    AdminOrganizersCreatenewComponent,
    AdminOrganizersApprovalsComponent,
    AdminParticipantsSearchComponent,
    AdminParticipantsCreatenewComponent,
    InquiryDetailsComponent,
    AdminMainComponent,
    OrganizerMainComponent,
    OrganizerProfileComponent,
    OrganizerEventComponent,
    OrganizerEventApplicationsComponent,
    OrganizerEventCreatenewEventComponent,
    OrganizerEventCreatenewPaymentComponent,
    OrganizerEventDetailsComponent,
    EventOrganizationDetailsComponent,
    EventDetailsComponent,
    EventPaymentDetailsComponent,
    OrganizerPaymentComponent,
    ParticipantProfileComponent,
    ParticipantEventComponent,
    ParticipantSettingsComponent,
    InquiryListComponent,
    CreateInquiryComponent,
    WebsiteHeaderComponent,
    WebsiteAdvertisementComponent,
    WebsiteFooterComponent,
    WebsiteFAQComponent,
    WebsiteAboutusComponent,
    WebsiteEventComponent,
    MoreDetailsComponent,
    ApprovalEventDetailsComponent,
    ApprovalOrganizationDetailsComponent,
    ApprovalPaymentDetailsComponent,
    EventRegistrationComponent,
    EventGroupComponent,
    EventCreateGroupComponent,
    EventJoinGroupComponent,
    ComingSoonPageComponent,
    EventInternalLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AutoCompleteModule,
    CalendarModule,
    CascadeSelectModule,
    CheckboxModule,
    ChipsModule,
    ColorPickerModule,
    DropdownModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    KnobModule,
    KeyFilterModule,
    ListboxModule,
    MultiSelectModule,
    PasswordModule,
    RadioButtonModule,
    RatingModule,
    SliderModule,
    SelectButtonModule,
    ToggleButtonModule,
    TriStateCheckboxModule,
    ButtonModule,
    SplitButtonModule,
    DataViewModule,
    GMapModule,
    OrderListModule,
    OrganizationChartModule,
    PaginatorModule,
    PickListModule,
    TableModule,
    TimelineModule,
    VirtualScrollerModule,
    AccordionModule,
    CardModule,
    DividerModule,
    FieldsetModule,
    PanelModule,
    SplitterModule,
    ScrollPanelModule,
    TabViewModule,
    ToolbarModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DynamicDialogModule,
    OverlayPanelModule,
    SidebarModule,
    TooltipModule,
    FileUploadModule,
    BreadcrumbModule,
    ContextMenuModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    PanelMenuModule,
    SlideMenuModule,
    StepsModule,
    TabMenuModule,
    TieredMenuModule,
    ChartModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    CarouselModule,
    GalleriaModule,
    DragDropModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BlockUIModule,
    CaptchaModule,
    InplaceModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    ScrollTopModule,
    SkeletonModule,
    TerminalModule,
    DeferModule,
    FocusTrapModule,
    RippleModule,
    ChipModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ReactiveFormsModule
  ],
  providers: [ConfirmationService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
