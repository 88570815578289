
<p-card routerLink="/event/create-group">
    Create a group
</p-card>

<p-card >
    Join a group<br>
    <input type="text" id="teamcode" name="teamcode" ngModel pInputText placeholder="Enter Team Code">
    <button routerLink="/event/join-group" pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Join Team"></button>
</p-card>
