import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-more-details',
  templateUrl: './more-details.component.html',
  styleUrls: ['./more-details.component.scss'],
})
export class MoreDetailsComponent implements OnInit {
  items: MenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Organization Details',
        routerLink: '/d/admin/admin-organizers-approvals/organizer-details',
      },
      {
        label: 'Event Details',
        routerLink: '/d/admin/admin-organizers-approvals/event-details',
      },
      {
        label: 'Payments',
        routerLink: '/d/admin/admin-organizers-approvals/payment-details',
      },
    ];
  }
}
