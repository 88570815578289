<div class="stepsdemo-content">
    <div class="main">
        <span class="header">Payment Details</span>
        <form [formGroup]="paymentForm">
            <span class="ln-2">
                <label for="receiptNo" style="display:block;margin-bottom:4px">Receipt No</label>
                <input type="text" id="receiptNo" formControlName="receiptNo" readonly pInputText placeholder="">
            </span>
            <span class="ln-2">
                <label for="amount" style="display:block;margin-bottom:4px">Amount (LKR)</label>
                <input type="text" id="amount" formControlName="amount" readonly pInputText placeholder="">
            </span>

            <span class="ln-2">
                <label for="dateTime" style="display:block;margin-bottom:4px">Deposit/ Transfer Date</label>
                <input type="text" id="dateTime" formControlName="dateTime" readonly pInputText placeholder="">
            </span>
            <span class="ln-2">
                <label for="bankName" style="display:block;margin-bottom:4px">Bank Name</label>
                <input type="text" id="bankName" formControlName="bankName" readonly pInputText placeholder="">
            </span>

            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Receipt Image</label>
                <input type="text" id="firstName" name="firstName"  pInputText placeholder="">
            </span>
            <span class="ln-1">
                <label for="description" style="display:block;margin-bottom:4px">Notes</label>
                <textarea type="text" id="description" formControlName="description" readonly pInputText placeholder=""></textarea>
            </span>
        </form>
        <div class="p-grid p-nogutter p-justify-end" style="width: 50%;margin: auto;margin-top: 20px;">
            <p-button label="Back" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
            <p-button class="next-btn" label="Close" (onClick)="closePage()" icon="pi pi-angle-right" iconPos="right"></p-button>
        </div>
    </div>


</div>
