<div class="dialog-box">

  <div class="grid-one">
    <span class="header">Organization Details</span>
    <form #form="ngForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Client Name</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Email Address</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Contact No</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Inquiry Title</label>
                <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Description</label>
                <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
    </form>
  </div>

  <div class="grid-two">
    <span class="header">Send Feedback</span>
    <form #form="ngForm">
            <span class="ln-1">
                <label for="eventcount" style="display:block;margin-bottom:4px">Client Email Address</label>
                <input type="text" id="eventcount" name="eventcount" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="peoplecount" style="display:block;margin-bottom:4px">Subject of Email</label>
                <input type="text" id="peoplecount" name="peoplecount" ngModel pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">Email Body</label>
                <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
            </span>
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="color: red;" label="Reject"></button>
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="color: rgb(254, 196, 0);" label="Pending"></button>
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="color: green;" label="Send"></button>
    </form>
  </div>

</div>
