import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import jwt_decode from 'jwt-decode';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-organizer-profile',
  templateUrl: './organizer-profile.component.html',
  styleUrls: ['./organizer-profile.component.scss']
})
export class OrganizerProfileComponent implements OnInit {

  organizerProfileForm = new FormGroup({
    _id: new FormControl(),
    organizationName: new FormControl(),
    organizationRegNo: new FormControl(),
    organizationContactNo: new FormControl(),
    organizationEmail: new FormControl(),
    organizationType: new FormControl(),
    organizationAddressLineOne: new FormControl(),
    organizationAddressLineTwo: new FormControl(),
    organizationCity: new FormControl(),
    organizationDescription: new FormControl()
  });

  representativeInfoForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    nicNo: new FormControl(),
    email: new FormControl(),
    contactNo: new FormControl()
  });

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  loggedUserToken: any;
  orgType: any;
  orgId: any = '';

  constructor(
    private fb: FormBuilder,
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    const decoded: any = jwt_decode(this.loggedUserToken);
    const getOrgProfile = JSON.parse( sessionStorage.getItem('orgProfile') as string);
    if (getOrgProfile) {
      this.loadForm(decoded, getOrgProfile);
    } else {
      this.createForm(decoded);
    }
  }

  createForm = (user: any) => {
    const path = this.dataService.BASE_PATH + this.dataService.ORGANIZER_PROFILE_SEARCH;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    const searchCriteria = {email: user.data.email};
    this.http.post(path, searchCriteria, header).toPromise().then(res => {
      const result = JSON.parse(JSON.stringify(res));

      sessionStorage.setItem('orgId', result.data[0]._id);
      sessionStorage.setItem('orgProfile', JSON.stringify(result.data[0]));

      this.organizerProfileForm = this.fb.group({
        _id: [result.data[0]._id ? result.data[0]._id : ''],
        organizationName: [result.data[0].organizationName ? result.data[0].organizationName : ''],
        organizationRegNo: [result.data[0].regNo ? result.data[0].regNo : ''],
        organizationContactNo: [result.data[0].organizationContactNo ? result.data[0].organizationContactNo : ''],
        organizationEmail: [result.data[0].organizationEmail ? result.data[0].organizationEmail : ''],
        organizationType: [result.data[0].organizationType ? result.data[0].organizationType : ''],
        organizationAddressLineOne: [result.data[0].organizationAddressLineOne ? result.data[0].organizationAddressLineOne : ''],
        organizationAddressLineTwo: [result.data[0].organizationAddressLineTwo ? result.data[0].organizationAddressLineTwo : ''],
        organizationCity: [result.data[0].city ? result.data[0].city : ''],
        organizationDescription: [result.data[0].description ? result.data[0].description : '']
      });

      this.representativeInfoForm = this.fb.group({
        firstName: [result.data[0].firstName ? result.data[0].firstName : ''],
        lastName: [result.data[0].lastName ? result.data[0].lastName : ''],
        nicNo: [result.data[0].nicNo ? result.data[0].nicNo : ''],
        email: [user.data.email],
        contactNo: [result.data[0].contactNo ? result.data[0].contactNo : '']
      });

    }).catch(e => {
      // ToDo: need to add notification
      console.log(e);
    });
  };

  loadForm = (user: any, data: any) => {
    this.organizerProfileForm = this.fb.group({
      _id: [data._id ? data._id : ''],
      organizationName: [data.organizationName ? data.organizationName : ''],
      organizationRegNo: [data.regNo ? data.regNo : ''],
      organizationContactNo: [data.organizationContactNo ? data.organizationContactNo : ''],
      organizationEmail: [data.organizationEmail ? data.organizationEmail : ''],
      organizationType: [data.organizationType ? data.organizationType : ''],
      organizationAddressLineOne: [data.organizationAddressLineOne ? data.organizationAddressLineOne : ''],
      organizationAddressLineTwo: [data.organizationAddressLineTwo ? data.organizationAddressLineTwo : ''],
      organizationCity: [data.city ? data.city : ''],
      organizationDescription: [data.description ? data.description : '']
    });

    this.representativeInfoForm = this.fb.group({
      firstName: [data.firstName ? data.firstName : ''],
      lastName: [data.lastName ? data.lastName : ''],
      nicNo: [data.nicNo ? data.nicNo : ''],
      email: [user.data.email],
      contactNo: [data.contactNo ? data.contactNo : '']
    });
  };

  createUpdateProfile(type: any) {
    const path = this.dataService.BASE_PATH + this.dataService.ORGANIZER_PROFILE;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    let tempOrganizerProfile: any;

    if (this.organizerProfileForm.value._id !== '') {
      tempOrganizerProfile = {
        _id: this.organizerProfileForm.value._id,
        organizationName: this.organizerProfileForm.value.organizationName,
        regNo: this.organizerProfileForm.value.organizationRegNo,
        organizationType: type?.name ? type.name : '',
        organizationEmail: this.organizerProfileForm.value.organizationEmail,
        organizationContactNo: this.organizerProfileForm.value.organizationContactNo,
        organizationAddressLineOne: this.organizerProfileForm.value.organizationAddressLineOne,
        organizationAddressLineTwo: this.organizerProfileForm.value.organizationAddressLineTwo,
        city: this.organizerProfileForm.value.organizationCity,
        description: this.organizerProfileForm.value.organizationDescription,
        firstName: this.representativeInfoForm.value.firstName,
        lastName: this.representativeInfoForm.value.lastName,
        nicNo: this.representativeInfoForm.value.nicNo,
        contactNo: this.representativeInfoForm.value.contactNo
      };
    } else {
      tempOrganizerProfile = {
        organizationName: this.organizerProfileForm.value.organizationName,
        regNo: this.organizerProfileForm.value.organizationRegNo,
        organizationType: type?.name ? type.name : '',
        organizationEmail: this.organizerProfileForm.value.organizationEmail,
        organizationContactNo: this.organizerProfileForm.value.organizationContactNo,
        organizationAddressLineOne: this.organizerProfileForm.value.organizationAddressLineOne,
        organizationAddressLineTwo: this.organizerProfileForm.value.organizationAddressLineTwo,
        city: this.organizerProfileForm.value.organizationCity,
        description: this.organizerProfileForm.value.organizationDescription,
        firstName: this.representativeInfoForm.value.firstName,
        lastName: this.representativeInfoForm.value.lastName,
        nicNo: this.representativeInfoForm.value.nicNo,
        email: this.representativeInfoForm.value.email,
        contactNo: this.representativeInfoForm.value.contactNo
      };
    }


    this.confirmationService.confirm({
      message: 'Are you sure that you want to create a profile?',
      accept: () => {
        this.http.put(path, tempOrganizerProfile, header).toPromise().then(res => {
          // ToDo: Add Notification
          console.log(res);
        }).catch(e => {
          // ToDo: Add Notification
          console.log(e);
        });
      },
      reject: () => {
        // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  }
}
