import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {OrganizerEventService} from '../../organizer-event.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-event-payment-details',
  templateUrl: './event-payment-details.component.html',
  styleUrls: ['./event-payment-details.component.scss']
})
export class EventPaymentDetailsComponent implements OnInit {

  paymentForm = new FormGroup({
    receiptNo: new FormControl(),
    amount: new FormControl(),
    dateTime: new FormControl(),
    bankName: new FormControl(),
    description: new FormControl()
  });

  constructor(
    private router: Router,
    public organizerEventService: OrganizerEventService,
    private fb: FormBuilder,
    private dialogRef: DynamicDialogRef
  ) {
  }

  ngOnInit(): void {
    if (this.organizerEventService.currentEventMore) {
      this.paymentForm = this.fb.group({
        receiptNo: [this.organizerEventService.currentEventMore.paymentId ? this.organizerEventService.currentEventMore.paymentId.receiptNo : ''],
        amount: [this.organizerEventService.currentEventMore.paymentId ? this.organizerEventService.currentEventMore.paymentId.amount : '1'],
        dateTime: [this.organizerEventService.currentEventMore.paymentId ? (new Date(this.organizerEventService.currentEventMore.paymentId.dateTime).toLocaleDateString() + ' - ' + new Date(this.organizerEventService.currentEventMore.paymentId.dateTime).toLocaleTimeString()) : ''],
        bankName: [this.organizerEventService.currentEventMore.paymentId ? this.organizerEventService.currentEventMore.paymentId.bankName : ''],
        description: [this.organizerEventService.currentEventMore.paymentId ? this.organizerEventService.currentEventMore.paymentId.description : '']
      });
    }
  }

  closePage() {
    this.router.navigate(['d/organizer/organizer-event']);
    this.dialogRef.close();
  }

  prevPage() {
    this.router.navigate(['d/organizer/organizer-event/event-details']);
  }

}
