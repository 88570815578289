<div class="search-main">
  <div class="container-one">
        <span class="p-float-label" style="width: 85%;">
            <input id="float-input" style="width: 98%;" type="text" pInputText>
            <label for="float-input">Enter Organization Name or Email</label>
        </span>
    <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="width: 15%;" label="Search"></button>
  </div>

  <div class="container-two">

    <div class="grid-one">
      <span class="header">Personal Details</span>
      <form #form="ngForm">
        <!-- <span class="ln-1">
            <label for="firstName" style="display:block;margin-bottom:4px">Organization Name</label>
            <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
        </span> -->
        <span class="ln-2">
                    <label for="firstName" style="display:block;margin-bottom:4px">First Name</label>
                    <input type="text" id="firstName" name="firstName" ngModel pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="lastName" style="display:block;margin-bottom:4px">Last Name</label>
                    <input type="text" id="lastName" name="lastName" ngModel pInputText placeholder="">
                </span>
        <span class="ln-3">
                    <label for="NICNo" style="display:block;margin-bottom:4px">NIC No</label>
                    <input type="text" id="NICNo" name="NICNo" ngModel pInputText placeholder="">
                </span>
        <span class="ln-3">
                    <label for="email" style="display:block;margin-bottom:4px">Email Address</label>
                    <input type="text" id="email" name="email" ngModel pInputText placeholder="">
                </span>
        <span class="ln-3">
                    <label for="contactNo" style="display:block;margin-bottom:4px">Contact No</label>
                    <input type="text" id="contactNo" name="contactNo" ngModel pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="address" style="display:block;margin-bottom:4px">Address</label>
                    <input type="text" id="address" name="address" ngModel pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="firstName" style="display:block;margin-bottom:4px">About (Optional)</label>
                    <textarea type="text" id="firstName" name="firstName" ngModel pInputText placeholder=""></textarea>
                </span>
      </form>
    </div>

    <div class="grid-two">
      <span class="header">Other Details</span>
      <form #form="ngForm">
                <span class="ln-1">
                    <label style="display:block;margin-bottom:4px">Food Preference</label>
                    <p-dropdown [options]="FoodPreference" name="FoodPreference" ngModel placeholder="Select" optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span><br>
        <span class="ln-1">
                    <label style="display:block;margin-bottom:4px">T-Shirt Size</label>
                    <p-dropdown [options]="TshirtSize" name="TshirtSize" ngModel placeholder="Select" optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span><br>
        <span class="ln-1">
                    <label style="display:block;margin-bottom:4px">Gender</label>
                    <p-dropdown [options]="Gender" name="Gender" ngModel placeholder="Select" optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span><br>
      </form>

    </div>

  </div>

</div>

