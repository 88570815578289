import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface Inquire{
  id : string;
  description : string;
  date : string;
  time : string;
  status : string;
}

@Component({
  selector: 'app-inquiry-list',
  templateUrl: './inquiry-list.component.html',
  styleUrls: ['./inquiry-list.component.scss']
})
export class InquiryListComponent implements OnInit {

  first = 0;

  rows = 10;

  inquires: Inquire[] = [
    {'id':'0','description':'ABC Event','date':'02.02.2021','time':'6.30pm','status':'active'},
    {'id':'1','description':'John Event','date':'02.02.2021','time':'6.30pm','status':'active'},
    {'id':'2','description':'Albert Event','date':'02.02.2021','time':'6.30pm','status':'close'}
  ]

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  btnClick(){
    this.router.navigateByUrl('../participant-inquiries-createnew')
  }

  show(){}

}
