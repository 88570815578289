import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrganizerEventService} from '../../organizer-event.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-event-organization-details',
  templateUrl: './event-organization-details.component.html',
  styleUrls: ['./event-organization-details.component.scss']
})
export class EventOrganizationDetailsComponent implements OnInit {

  organizationDetailsForm = new FormGroup({
    organizationName: new FormControl(),
    regNo: new FormControl(),
    organizationContactNo: new FormControl(),
    organizationEmail: new FormControl(),
    organizationType: new FormControl(),
    organizationAddress: new FormControl(),
    description: new FormControl()
  });

  representativeDetailsForm = new FormGroup({
    name: new FormControl(),
    nic: new FormControl(),
    email: new FormControl(),
    contactNo: new FormControl()
  });

  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  constructor(
    private router: Router,
    public organizerEventService: OrganizerEventService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    if (this.organizerEventService.currentEventMore) {
      this.organizationDetailsForm = this.fb.group({
        organizationName: [this.organizerEventService?.currentEventMore?.organizerId?.organizationName ? this.organizerEventService.currentEventMore.organizerId.organizationName : ''],
        regNo: [this.organizerEventService?.currentEventMore?.organizerId?.regNo ? this.organizerEventService.currentEventMore.organizerId.regNo : ''],
        organizationContactNo: [this.organizerEventService?.currentEventMore?.organizerId?.organizationContactNo ? this.organizerEventService.currentEventMore.organizerId.organizationContactNo : ''],
        organizationEmail: [this.organizerEventService?.currentEventMore?.organizerId?.organizationEmail ? this.organizerEventService.currentEventMore.organizerId.organizationEmail : ''],
        organizationType: [this.organizerEventService?.currentEventMore?.organizerId?.organizationType ? this.organizerEventService.currentEventMore.organizerId.organizationType : ''],
        organizationAddress: [this.organizerEventService?.currentEventMore?.organizerId ? (this.organizerEventService.currentEventMore.organizerId.addressLineOne + ',' + this.organizerEventService.currentEventMore.organizerId.addressLineTwo + ',' + this.organizerEventService.currentEventMore.organizerId.city) : ''],
        description: [this.organizerEventService.currentEventMore?.organizerId?.description ? this.organizerEventService.currentEventMore.organizerId.description : '']
      });

      this.representativeDetailsForm = this.fb.group({
        name: [this.organizerEventService.currentEventMore.organizerId ? (this.organizerEventService.currentEventMore.organizerId.firstName + ' ' + this.organizerEventService.currentEventMore.organizerId.lastName) : ''],
        nic: [this.organizerEventService.currentEventMore.organizerId?.nicNo ? this.organizerEventService.currentEventMore.organizerId.nicNo : ''],
        email: [this.organizerEventService.currentEventMore.organizerId?.email ? this.organizerEventService.currentEventMore.organizerId.email : ''],
        contactNo: [this.organizerEventService.currentEventMore.organizerId?.contactNo ? this.organizerEventService.currentEventMore.organizerId.contactNo : '']
      });
    }
  }


  nextPage() {
    this.router.navigate(['d/organizer/organizer-event/event-details']);
  }

}
