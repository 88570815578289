import {Component, OnInit} from '@angular/core';
import {CheckboxModule} from 'primeng/checkbox';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-participant-profile',
  templateUrl: './participant-profile.component.html',
  styleUrls: ['./participant-profile.component.scss']
})
export class ParticipantProfileComponent implements OnInit {

  loggedUserToken: any;
  isEditModeOn: boolean = false;

  participantProfileFormPersonalDetails = new FormGroup({
    _id: new FormControl(),
    participantFirstName: new FormControl(),
    participantLastName: new FormControl(),
    participantNicNo: new FormControl(),
    participantEmail: new FormControl(),
    participantContactNo: new FormControl(),
    participantAddress: new FormControl(),
    participantAbout: new FormControl()
  });

  participantProfileFormOtherDetails = new FormGroup({
    foodPreference: new FormControl(),
    tShirtSize: new FormControl(),
    gender: new FormControl(),
    userGroup: new FormControl()
  });

  FoodPreference = [
    {
      name: 'Non-Veg',
      code: 'NVEG'
    },
    {
      name: 'Veg',
      code: 'VEG'
    }
  ];

  TshirtSize = [
    {
      name: 'Small',
      code: 'S'
    },
    {
      name: 'Medium',
      code: 'M'
    },
    {
      name: 'Large',
      code: 'L'
    }
  ];

  Gender = [
    {
      name: 'Male',
      code: 'M'
    },
    {
      name: 'Female',
      code: 'F'
    },
    {
      name: 'Other',
      code: 'O'
    }
  ];

  selectedValues: string[] = [];

  constructor(
    private fb: FormBuilder,
    private dataService: DataServiceService,
    private confirmationService: ConfirmationService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    const decoded: any = jwt_decode(this.loggedUserToken);
    this.createForm(decoded);
  }

  loadForm = (user: any, data: any) => {
    this.participantProfileFormPersonalDetails = this.fb.group({
      _id: [data._id ? data._id : ''],
      participantFirstName: [data.participantFirstName ? data.participantFirstName : ''],
      participantLastName: [data.participantLastName ? data.participantLastName : ''],
      participantNicNo: [data.participantNicNo ? data.participantNicNo : ''],
      participantEmail: [data.participantEmail ? data.participantEmail : ''],
      participantContactNo: [data.participantContactNo ? data.participantContactNo : ''],
      participantAddress: [data.participantAddress ? data.participantAddress : ''],
      participantAbout: [data.participantAbout ? data.participantAbout : '']
    });

    this.participantProfileFormOtherDetails = this.fb.group({
      foodPreference: [data.foodPreference ? data.foodPreference : ''],
      tShirtSize: [data.tShirtSize ? data.tShirtSize : ''],
      gender: [data.gender ? data.gender : ''],
      userGroup: [data.userGroup ? data.userGroup : '']
    });
  };

  createUpdateProfile = () => {
    this.isEditModeOn = !this.isEditModeOn;
    console.log(this.participantProfileFormPersonalDetails.value);
    console.log(this.participantProfileFormOtherDetails.value);

    const path = this.dataService.BASE_PATH + this.dataService.PARTICIPANT_PROFILE;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    let tempParticipantProfile: any;
    const participantProfileId = sessionStorage.getItem('participantId');

    if (this.participantProfileFormPersonalDetails.value) {
      tempParticipantProfile = {
        _id: participantProfileId ? participantProfileId : null,
        firstName: this.participantProfileFormPersonalDetails?.value?.participantFirstName ? this.participantProfileFormPersonalDetails.value.participantFirstName : null,
        lastName: this.participantProfileFormPersonalDetails?.value?.participantLastName ? this.participantProfileFormPersonalDetails.value.participantLastName : null,
        nicNo: this.participantProfileFormPersonalDetails?.value?.participantNicNo ? this.participantProfileFormPersonalDetails.value.participantNicNo : null,
        email: this.participantProfileFormPersonalDetails?.value?.participantEmail ? this.participantProfileFormPersonalDetails.value.participantEmail : null,
        contactNo: this.participantProfileFormPersonalDetails?.value?.participantContactNo ? this.participantProfileFormPersonalDetails.value.participantContactNo : null,
        gender: this.participantProfileFormOtherDetails?.value?.gender?.name ? this.participantProfileFormOtherDetails.value.gender.name : null,
        addressLineOne: this.participantProfileFormPersonalDetails?.value?.participantAddress ? this.participantProfileFormPersonalDetails.value.participantAddress : null,
        addressLineTwo: '',
        city: '',
        description: this.participantProfileFormPersonalDetails?.value?.participantAbout ? this.participantProfileFormPersonalDetails.value.participantAbout : null,
        tShirtSize: this.participantProfileFormOtherDetails?.value?.tShirtSize?.name ? this.participantProfileFormOtherDetails.value.tShirtSize.name : null,
        foodPreference: this.participantProfileFormOtherDetails?.value?.foodPreference?.name ? this.participantProfileFormOtherDetails.value.foodPreference.name : null,
        userGroup: this.participantProfileFormOtherDetails?.value?.userGroup ? this.participantProfileFormOtherDetails.value.userGroup : null,
      };
    }

    this.confirmationService.confirm({
      message: 'Are you sure that you want to create/update a profile?',
      accept: () => {
        this.http.post(path, tempParticipantProfile, header).toPromise().then(res => {
          // ToDo: Add Notification
          const decoded: any = jwt_decode(this.loggedUserToken);
          this.createForm(decoded);
        }).catch(e => {
          // ToDo: Add Notification
          console.log(e);
        });
      },
      reject: () => {
        // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  };

  createForm = (user: any) => {
    const path = this.dataService.BASE_PATH + this.dataService.PARTICIPANT_PROFILE_SEARCH + user.data.email;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    this.http.get(path, header).toPromise().then(res => {
      const result = JSON.parse(JSON.stringify(res));
      sessionStorage.setItem('participantId', result?.data?._id ? result?.data?._id : null);
      sessionStorage.setItem('participantProfile', JSON.stringify(result.data));

      this.participantProfileFormPersonalDetails = this.fb.group({
        _id: [result?.data?._id ? result?.data?._id : ''],
        participantFirstName: [result?.data?.firstName ? result.data.firstName : ''],
        participantLastName: [result?.data?.lastName ? result.data.lastName : ''],
        participantNicNo: [result?.data?.nicNo ? result.data.nicNo : ''],
        participantEmail: [user?.data?.email ? user.data.email : ''],
        participantContactNo: [result?.data?.contactNo ? result.data.contactNo : ''],
        participantAddress: [result?.data?.addressLineOne ? result.data.addressLineOne : ''],
        participantAbout: [result?.data?.description ? result.data.description : '']
      });

      this.participantProfileFormOtherDetails = this.fb.group({
        foodPreference: [result?.data?.foodPreference ? result.data.foodPreference : ''],
        tShirtSize: [result?.data?.tShirtSize ? result.data.tShirtSize : ''],
        gender: [result?.data?.gender ? result.data.gender : ''],
        userGroup: [result?.data?.userGroup ? result.data.userGroup : '']
      });

    }).catch(e => {
      // ToDo: need to add notification
      console.log(e);
    });

  };

  editProfile() {
    this.isEditModeOn = !this.isEditModeOn;
  }
}
