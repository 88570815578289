<div class="search-main">
  <div class="container-one">
        <span class="p-float-label" style="width: 85%;">
            <input id="float-input" style="width: 98%;" type="text" pInputText #search
                   (keydown.enter)="searchParticipant(search.value)">
            <label for="float-input">Enter Participant's Email</label>
        </span>
    <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="width: 15%;"
            label="Search" (click)="searchParticipant(search.value)"></button>
  </div>

  <div class="container-two">
    <div class="grid-one">
      <span class="header">Personal Details</span>
      <form [formGroup]="createParticipantForm1">
                <span class="ln-2">
                    <label for="firstName">First Name</label>
                    <input required type="text" id="firstName" formControlName="firstName" pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="lastName">Last Name</label>
                    <input required type="text" id="lastName" formControlName="lastName" pInputText placeholder="">
                </span>
        <span class="ln-3">
                    <label for="nicNo">NIC No</label>
                    <input required type="text" id="nicNo" formControlName="nicNo" pInputText placeholder="">
                </span>
        <span class="ln-3">
                    <label for="email">Email Address</label>
                    <input required type="text" id="email" formControlName="email" pInputText placeholder="">
                </span>
        <span class="ln-3">
                    <label for="contactNo">Contact No</label>
                    <input required type="text" id="contactNo" formControlName="contactNo" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="address">Address</label>
                    <input required type="text" id="address" formControlName="addressLineOne" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="about">About (Optional)</label>
                    <textarea type="text" id="about" formControlName="description" pInputText placeholder=""></textarea>
                </span>
      </form>
    </div>

    <div class="grid-two">
      <span class="header">Other Details</span>
      <form [formGroup]="createParticipantForm2">
                <span class="ln-1">
                    <label>Food Preference</label>
                    <p-dropdown [options]="foodPreference" formControlName="foodPreference" placeholder="Select"
                                optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span>
        <span class="ln-1">
                    <label>T-Shirt Size</label>
                    <p-dropdown [options]="tShirtSize" formControlName="tShirtSize" placeholder="Select"
                                optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span>
        <span class="ln-1">
                    <label>Gender</label>
                    <p-dropdown [options]="gender" formControlName="gender" placeholder="Select" optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span><br>
        <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Create"
                (click)="createProfile()"></button>
      </form>
    </div>

  </div>

</div>
