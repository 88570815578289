<!-- <p>admin-organizers-search works!</p> -->

<div class="search-main">
  <div class="container-one">
        <span class="p-float-label" style="width: 85%;">
            <input id="float-input" style="width: 98%;" type="text" pInputText #searchORG
                   (keyup.enter)="searchOrganizationProfiles(searchORG.value)">
            <label for="float-input">Enter Organization Name or Email</label>
        </span>
    <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" style="width: 15%;"
            label="Search" (click)="searchOrganizationProfiles(searchORG.value)"></button>
  </div>

  <div class="container-two">

    <div class="grid-one">
      <span class="header">Organization Details</span>
      <form [formGroup]="organizerDetailsForm">
                <span class="ln-1">
                    <label for="organizationName" style="display:block;margin-bottom:4px">Organization Name</label>
                    <input type="text" id="organizationName" formControlName="organizationName" pInputText
                           placeholder="">
                </span>
        <span class="ln-2">
                    <label for="regNo" style="display:block;margin-bottom:4px">Reg. No (If any)</label>
                    <input type="text" id="regNo" formControlName="regNo" pInputText placeholder="">
                </span>
        <span class="ln-2">
                    <label for="organizationContactNo" style="display:block;margin-bottom:4px">Contact No</label>
                    <input type="text" id="organizationContactNo" formControlName="organizationContactNo" pInputText
                           placeholder="">
                </span>
        <span class="ln-2">
                    <label for="organizationEmail" style="display:block;margin-bottom:4px">Email Address</label>
                    <input type="text" id="organizationEmail" formControlName="organizationEmail" pInputText
                           placeholder="">
                </span>
        <span class="ln-2">
                    <label style="display:block;margin-bottom:4px">Organization Type</label>
                    <p-dropdown [options]="OrganizationType" formControlName="organizationType" placeholder="Select"
                                optionLabel="name"
                                [showClear]="true"></p-dropdown>
                </span><br>
        <span class="ln-1">
                    <label for="organizationAddressLineOne"
                           style="display:block;margin-bottom:4px">Address Line 1</label>
                    <input type="text" id="organizationAddressLineOne" formControlName="organizationAddressLineOne"
                           pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="organizationAddressLineTwo"
                           style="display:block;margin-bottom:4px">Address Line 2</label>
                    <input type="text" id="organizationAddressLineTwo" formControlName="organizationAddressLineTwo"
                           pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="city" style="display:block;margin-bottom:4px">City</label>
                    <input type="text" id="city" formControlName="city" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="description" style="display:block;margin-bottom:4px">About (Optional)</label>
                    <textarea type="text" id="description" formControlName="description" pInputText
                              placeholder=""></textarea>
                </span>
      </form>
    </div>

    <div class="grid-two">
      <span class="header">Representative's Information</span>
      <form [formGroup]="representativeDetailsForm">
                <span class="ln-1">
                    <label for="firstName" style="display:block;margin-bottom:4px">First Name</label>
                    <input type="text" id="firstName" formControlName="firstName" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="lastName" style="display:block;margin-bottom:4px">Last Name</label>
                    <input type="text" id="lastName" formControlName="lastName" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="nicNo" style="display:block;margin-bottom:4px">NIC No.</label>
                    <input type="text" id="nicNo" formControlName="nicNo" pInputText placeholder="">
                </span>
        <span class="ln-1">
                    <label for="email" style="display:block;margin-bottom:4px">Email</label>
                    <input type="text" id="email" formControlName="email" pInputText placeholder="">
                </span>

        <span class="ln-1">
                    <label for="contactNo" style="display:block;margin-bottom:4px">Contact No</label>
                    <input type="text" id="contactNo" formControlName="contactNo" pInputText placeholder="">
                </span>
      </form>
      <button *ngIf="isCreateNew" pButton pRipple type="button" class="p-button-outlined p-button-secondary"
              label="Create New" (click)="createOrganization()"></button>
<button *ngIf="!isCreateNew" pButton pRipple type="button" class="p-button-outlined p-button-secondary"
              label="Update" (click)="createOrganization()"></button>

    </div>

  </div>

</div>
