import {Component, Injectable, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NotificationsService} from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  BASE_PATH = environment.BASE_PATH;
  loggedUserToken: any;
  SELECTED_EVENT: any = [];

  // URI
  AUTH = '/auth';
  ADMIN_PROFILE = '/admin-profile';
  ORGANIZER_PROFILE = '/organizer-profile';
  ORGANIZER_PROFILE_SEARCH = '/organizer-profile/search';
  PARTICIPANT_PROFILE = '/participant-profile';
  PARTICIPANT_PROFILE_SEARCH = '/participant-profile/';
  GENERAL_EVENT = '/general-event';
  GENERAL_EVENT_SEARCH = '/general-event/search';
  CREATE_ACCOUNT = '/auth/user/';
  EVENT_REGISTRATION = '/event-registration';
  EVENT_REGISTRATION_SEARCH = '/event-registration/search';

  IS_USER_LOGGED_IN: boolean = false;
  LOGGED_USER_DATA: any;

  constructor(
    private http: HttpClient,
    private notification: NotificationsService,
  ) {
    this.getLoggedUser();
  }

  userLogin = (credentials: any) => {
    return this.http.post(this.BASE_PATH + this.AUTH, credentials);
  };

  getLoggedUser = () => {
    this.loggedUserToken = sessionStorage.getItem('token');
    if (this.loggedUserToken) {
      this.IS_USER_LOGGED_IN = true;
      const loggedUser = JSON.parse(atob(this.loggedUserToken.split('.')[1]));
      if (loggedUser) {
        this.LOGGED_USER_DATA = loggedUser.data;
      }
    } else {
      this.IS_USER_LOGGED_IN = false;
    }
  };

  postRegistration = (data: any) => {
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(JSON.stringify(this.loggedUserToken))))};
    return this.http.post(this.BASE_PATH + this.EVENT_REGISTRATION, data, header);
  };
}
