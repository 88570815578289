<div class="profile-main">

  <div class="grid-one">
    <span class="header">Organization Details</span>
    <form [formGroup]="organizerProfileForm">
            <span class="ln-1">
                <label for="organizationName" style="display:block;margin-bottom:4px">Organization Name</label>
                <input type="text" id="organizationName" formControlName="organizationName"  pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="organizationRegNo" style="display:block;margin-bottom:4px">Reg. No (If any)</label>
                <input type="text" id="organizationRegNo" formControlName="organizationRegNo"  pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="organizationContactNo" style="display:block;margin-bottom:4px">Contact No</label>
                <input type="text" id="organizationContactNo" formControlName="organizationContactNo"  pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label for="organizationEmail" style="display:block;margin-bottom:4px">Email Address</label>
                <input type="text" id="organizationEmail" formControlName="organizationEmail"  pInputText placeholder="">
            </span>
      <span class="ln-2">
                <label style="display:block;margin-bottom:4px">Organization Type</label>
                <p-dropdown [options]="OrganizationType"  placeholder="Select" #orgType
                            optionLabel="name" [showClear]="true"></p-dropdown>
            </span><br>
      <span class="ln-3">
                <label for="organizationAddressLineOne" style="display:block;margin-bottom:4px">Address Line 1</label>
                <input type="text" id="organizationAddressLineOne" formControlName="organizationAddressLineOne"  pInputText placeholder="">
            </span>
      <span class="ln-3">
                <label for="organizationAddressLineTwo" style="display:block;margin-bottom:4px">Address Line 2</label>
                <input type="text" id="organizationAddressLineTwo" formControlName="organizationAddressLineTwo"  pInputText placeholder="">
            </span>
      <span class="ln-3">
                <label for="organizationCity" style="display:block;margin-bottom:4px">City</label>
                <input type="text" id="organizationCity" formControlName="organizationCity"  pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="organizationDescription" style="display:block;margin-bottom:4px">About (Optional)</label>
                <textarea type="text" id="organizationDescription" formControlName="organizationDescription"  pInputText placeholder=""></textarea>
            </span>
    </form>
  </div>

  <div class="grid-two">
    <span class="header" style="margin-top: 10px;">Representative's Information</span>
    <form [formGroup]="representativeInfoForm">
            <span class="ln-1">
                <label for="firstName" style="display:block;margin-bottom:4px">First Name</label>
                <input type="text" id="firstName" formControlName="firstName"  pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="lastName" style="display:block;margin-bottom:4px">Last Name</label>
                <input type="text" id="lastName" formControlName="lastName"  pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="nicNo" style="display:block;margin-bottom:4px">NIC No.</label>
                <input type="text" id="nicNo" formControlName="nicNo"  pInputText placeholder="">
            </span>
      <span class="ln-1">
                <label for="email" style="display:block;margin-bottom:4px">Personal Email</label>
                <input type="text" id="email" formControlName="email"  pInputText placeholder="" readonly>
            </span>
      <span class="ln-1">
                <label for="contactNo" style="display:block;margin-bottom:4px">Contact No</label>
                <input type="text" id="contactNo" formControlName="contactNo"  pInputText placeholder="">
            </span>
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary"  label="Update" (click)="createUpdateProfile(orgType.value)"></button>
    </form>
  </div>



</div>
