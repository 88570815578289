import { Component, OnInit } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import { InquiryDetailsComponent } from './inquiry-details/inquiry-details.component';
import {DynamicDialogRef} from 'primeng/dynamicdialog';


export interface Inquire{
  id : string;
  user : string;
  description : string;
  date : string;
  time : string;
  status : string;
  userType : string;
}

@Component({
  selector: 'app-admin-inquiries',
  templateUrl: './admin-inquiries.component.html',
  styleUrls: ['./admin-inquiries.component.scss'],
  providers: [DialogService, DynamicDialogRef]
})
export class AdminInquiriesComponent implements OnInit {

  first = 0;

  rows = 10;

  inquires: Inquire[] = [
    {'id':'0','user':'ABC Silva','description':'ABC Event','date':'02.02.2021','time':'6.30pm','status':'reject','userType':'Organizer'},
    {'id':'1','user':'John Dias','description':'John Event','date':'02.02.2021','time':'6.30pm','status':'active','userType':'Organizer'},
    {'id':'2','user':'Albert Perera','description':'Albert Event','date':'02.02.2021','time':'6.30pm','status':'pending','userType':'Participant'}
  ]

  constructor(public dialogRef: DynamicDialogRef ,public dialogService: DialogService) { }

  ngOnInit(): void {
  }

  show() {
      this.dialogRef = this.dialogService.open(InquiryDetailsComponent, {
          width: '70%',
          contentStyle: {"height": "max-content", "overflow": "auto"}
      });
  }

}
