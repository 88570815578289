import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-sign-up-type',
  templateUrl: './sign-up-type.component.html',
  styleUrls: ['./sign-up-type.component.scss']
})
export class SignUpTypeComponent implements OnInit {

  constructor(
    private primengConfig:PrimeNGConfig
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

}
