<nav class="main">
  <div class="side-nav">
    <emp-logo class="logo"></emp-logo>
    <p-tieredMenu styleClass="side-menu-item" [model]="items"></p-tieredMenu>
    <button *ngFor="let data of items;" [routerLink]="data.routerLink" hidden></button>
  </div>
  <div>
    <div class="menu-topic">
      <div class="menu-title">
      <i class="pi pi-align-justify"></i>
       <span class="title">
<!--        {{dashboardService.DASHBOARD_HEADER}}-->
         <p-breadcrumb [model]="titles"></p-breadcrumb>
       </span>
      </div>
      <nav class="menu-user">
        <div class="menu-icons">
        <i class="pi pi-bell" pBadge *ngIf="notificationCount > 0" value="{{ notificationCount}}" (click)="notification.toggle($event)" ></i>
          <i class="pi pi-bell" *ngIf="!(notificationCount > 0)" (click)="notification.toggle($event)" ></i>
          <i class="pi pi-globe" (click)="language.toggle($event)" ></i>
        <i class="pi pi-cog"></i>
        </div>
        <p-avatar class="user-pic" image="/assets/avatars/1.png" size="large" shape="circle" (click)="menu.toggle($event)"></p-avatar>
      </nav>
      <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
      <p-menu #notification [popup]="true" [model]="notifications"></p-menu>
      <p-menu #language [popup]="true" [model]="languages"></p-menu>
    </div>

    <router-outlet></router-outlet>
  </div>
</nav>
