import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {MoreDetailsComponent} from './more-details/more-details.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {AdminEventService} from './admin-event.service';

export interface Event {
  paymentId: string;
  groupId: string;
  eventName: string;
  organizerId: string;
  formRequestedDetails: [];
  description: string;
  startDateTime: Date;
  endDateTime: Date;
  registrationDeadline: Date;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  organizedBy: string;
  maxParticipants: number;
  registrationFee: number;
  category: [];
  targetGroup: [];
  facebookUrl: string;
  twitterUrl: string;
  linkedinUrl: string;
  websiteUrl: string;
  eventLocationLink: string;
  eventBannerOne: string;
  eventBannerTwo: string;
  requestSubmittedDate: Date;
  status: string;
  isPaid: boolean;
  isApproved: boolean;
}

@Component({
  selector: 'app-admin-organizers-approvals',
  templateUrl: './admin-organizers-approvals.component.html',
  styleUrls: ['./admin-organizers-approvals.component.scss'],
  providers: [DialogService, DynamicDialogRef],
})
export class AdminOrganizersApprovalsComponent implements OnInit {
  moreitems: MenuItem[] = [];

  first = 0;
  rows = 10;
  loggedUserToken: any;
  events: Event[] = [
    // {
    //   id: '0',
    //   organization: 'ABC Silva',
    //   eventTitle: 'ABC Event',
    //   date: '02.02.2021',
    //   time: '6.30pm',
    //   status: 'decline',
    //   lastUpdated: '03.01.2021',
    // },
    // {
    //   id: '1',
    //   organization: 'John Dias',
    //   eventTitle: 'John Event',
    //   date: '02.02.2021',
    //   time: '6.30pm',
    //   status: 'approved',
    //   lastUpdated: '03.01.2021',
    // },
    // {
    //   id: '2',
    //   organization: 'Albert Perera',
    //   eventTitle: 'Albert Event',
    //   date: '02.02.2021',
    //   time: '6.30pm',
    //   status: 'pending',
    //   lastUpdated: '03.01.2021',
    // },
  ];

  constructor(
    private router: Router,
    public dialogRef: DynamicDialogRef,
    public dialogService: DialogService,
    private dataService: DataServiceService,
    private http: HttpClient,
    public adminEventService: AdminEventService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    this.getAllEvents('');
  }

  show = (event: any) => {
    this.adminEventService.currentEvent = event;
    this.router.navigateByUrl(
      '/d/admin/admin-organizers-approvals/organizer-details'
    );
    this.dialogRef = this.dialogService.open(MoreDetailsComponent, {
      width: '80%',
      contentStyle: {height: 'max-content', overflow: 'auto'},
    });
  };

  getAllEvents(searchCriteria: any) {
    const path = this.dataService.BASE_PATH + this.dataService.GENERAL_EVENT_SEARCH;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};

    if (searchCriteria != null) {
      this.http.post(path, searchCriteria).toPromise().then(re => {
        const res = JSON.parse(JSON.stringify(re)).data;
        this.events = res;
      }).catch(e => {
        console.log(e);
        // ToDo: Add Notification
      });
    } else {
      searchCriteria = {};
      this.http.post(path, searchCriteria).toPromise().then(re => {
        const res = JSON.parse(JSON.stringify(re)).data;
        this.events = res;
      }).catch(e => {
        console.log(e);
        // ToDo: Add Notification
      });
    }

  }
}
