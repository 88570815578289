import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataServiceService} from '../../common/services/data-service.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-admin-participants-createnew',
  templateUrl: './admin-participants-createnew.component.html',
  styleUrls: ['./admin-participants-createnew.component.scss']
})
export class AdminParticipantsCreatenewComponent implements OnInit {

  createParticipantForm1 = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    nicNo: new FormControl(),
    email: new FormControl(),
    contactNo: new FormControl(),
    addressLineOne: new FormControl(),
    addressLineTwo: new FormControl(),
    city: new FormControl(),
    description: new FormControl()
  });
  createParticipantForm2 = new FormGroup({
    gender: new FormControl(),
    tShirtSize: new FormControl(),
    foodPreference: new FormControl()
  });

  loggedUserToken: any;

  foodPreference = [
    {
      name: 'Non-Veg',
      code: 'NON-VEG'
    },
    {
      name: 'Veg',
      code: 'VEG'
    },
    {
      name: 'Egg',
      code: 'EGG'
    }
  ];
  gender = [
    {
      name: 'Male',
      code: 'MALE'
    },
    {
      name: 'Female',
      code: 'FEMALE'
    },
    {
      name: 'Other',
      code: 'OTHER'
    }
  ];
  tShirtSize = [
    {
      name: 'Extra Small',
      code: 'XS'
    },
    {
      name: 'Small',
      code: 'S'
    },
    {
      name: 'Medium',
      code: 'M'
    },
    {
      name: 'Large',
      code: 'L'
    },
    {
      name: 'Extra Large',
      code: 'XL'
    },
    {
      name: 'Double Extra Large',
      code: 'XXL'
    }
  ];

  constructor(
    private fb: FormBuilder,
    private dataService: DataServiceService,
    private http: HttpClient,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.loggedUserToken = JSON.stringify(sessionStorage.getItem('token'));
    this.createForm();
  }

  createForm = () => {
    this.createParticipantForm1 = this.fb.group({
      firstName: [''],
      lastName: [''],
      nicNo: [''],
      email: [''],
      contactNo: [''],
      addressLineOne: [''],
      addressLineTwo: [''],
      city: [''],
      description: [''],
    });
    this.createParticipantForm2 = this.fb.group({
      gender: [''],
      tShirtSize: [''],
      foodPreference: ['']
    });
  };

  createProfile = () => {
    const tempProfile = {
      firstName: this.createParticipantForm1.value.firstName,
      lastName: this.createParticipantForm1.value.lastName,
      nicNo: this.createParticipantForm1.value.nicNo,
      email: this.createParticipantForm1.value.email,
      contactNo: this.createParticipantForm1.value.contactNo,
      addressLineOne: this.createParticipantForm1.value.addressLineOne,
      addressLineTwo: this.createParticipantForm1.value.addressLineTwo,
      city: this.createParticipantForm1.value.city,
      description: this.createParticipantForm2.value.description,
      gender: this.createParticipantForm2.value.gender.code,
      tShirtSize: this.createParticipantForm2.value.tShirtSize.code,
      foodPreference: this.createParticipantForm2.value.foodPreference.code
    };
    const path = this.dataService.BASE_PATH + this.dataService.PARTICIPANT_PROFILE;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};

    this.confirmationService.confirm({
      message: 'Are you sure that you want to create a profile?',
      accept: () => {
        this.http.post(path, tempProfile, header).toPromise().then(res => {
          // ToDo: Add Notification
          this.createParticipantForm1.reset();
          this.createParticipantForm2.reset();
        }).catch(e => {
          // ToDo: Add Notification
          console.log(e);
        });
      },
      reject: () => {
        // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  };

  searchParticipant = (value: string) => {
    console.log(value);
    const path = this.dataService.BASE_PATH + this.dataService.PARTICIPANT_PROFILE + '/' + value;
    const header = {headers: new HttpHeaders().set('Authorization', ('Bearer ' + JSON.parse(this.loggedUserToken)))};
    if (value) {
      this.http.get(path, header).toPromise().then(res => {
        const participantProfileTemp = JSON.parse(JSON.stringify(res)).data;
        if (participantProfileTemp) {
          this.createParticipantForm1 = this.fb.group({
            firstName: [participantProfileTemp.firstName],
            lastName: [participantProfileTemp.lastName],
            nicNo: [participantProfileTemp.nicNo],
            email: [participantProfileTemp.email],
            contactNo: [participantProfileTemp.contactNo],
            addressLineOne: [participantProfileTemp.addressLineOne],
            addressLineTwo: [participantProfileTemp.addressLineTwo],
            city: [participantProfileTemp.city],
            description: [participantProfileTemp.description],
          });
          this.createParticipantForm2 = this.fb.group({
            gender: [participantProfileTemp.gender],
            tShirtSize: [participantProfileTemp.tShirtSize],
            foodPreference: [participantProfileTemp.foodPreference]
          });
        }
      }).catch(e => {
        // ToDo: Add Notification
        console.log(e);
      });
    }
  };
}
