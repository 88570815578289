<div class="sign-up-main">
  <emp-logo></emp-logo>
  <div class="container-one">
    <button pButton pRipple type="button" label="Organization" routerLink='organization' class="p-button-raised p-button-secondary p-button-text"></button>
    <br>
    <button pButton pRipple type="button" label="Participant" routerLink='participant' class="p-button-raised p-button-secondary p-button-text"></button>
    <br>
    <span>
        <label>Do you have an account? <a href="/login">Login here</a></label>
    </span>
  </div>
</div>
