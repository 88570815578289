import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataServiceService} from '../../common/services/data-service.service';
import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-website-event',
  templateUrl: './website-event.component.html',
  styleUrls: ['./website-event.component.scss']
})
export class WebsiteEventComponent implements OnInit {

  event: any = [];
  isSubmitting = false;
  isLoading = false;
  isDone = false;

  constructor(
    private http: HttpClient,
    public dataService: DataServiceService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private notification: NotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.event = this.dataService.SELECTED_EVENT;
    if (this.event || this.event == null) {
      const tempData = sessionStorage.getItem('selectedEvent');
      if (tempData != null) {
        this.event = JSON.parse(tempData);
      }
    }
  }

  register = () => {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to register with this event?',
      accept: () => {

        this.isSubmitting = true;
        this.isLoading = false;
        if (this.dataService.IS_USER_LOGGED_IN && this.dataService.LOGGED_USER_DATA) {
          const data = {
            'participantEmail': this.dataService.LOGGED_USER_DATA.email,
            'paymentId': null,
            'eventId': this.event._id,
            'groupId': null,
            'status': 'pending'
          };
          this.dataService.postRegistration(data).toPromise().then(res => {
              const response = JSON.parse(JSON.stringify(res));

              this.notification.success('Success!', 'you have successfully registered!', {
                position: ['bottom', 'right'],
                timeOut: 3000,
                animate: 'fade',
                showprogressbar: true
              });

              this.isLoading = true;
              this.isDone = true;

              setTimeout(() => {
                this.isSubmitting = false;
                this.isLoading = false;
                this.isDone = false;
              }, 2000);

            }
          ).catch(e => {
            {
              this.notification.warn('Error!', e.error.details, {
                position: ['bottom', 'right'],
                timeOut: 3000,
                animate: 'fade',
                showprogressbar: true
              });

              this.isLoading = true;
              this.isDone = false;

              setTimeout(() => {
                this.isSubmitting = false;
                this.isLoading = false;
                this.isDone = false;
              }, 3000);
            }
          });
        }
      },
      reject: () => {
        // ToDo: add something if needed to take any action when rejecting the confirmation
      }
    });
  };
}
