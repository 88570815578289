<div class="search-main">
    <div class="container-one">
    </div>

    <div class="container-two">

        <div class="grid-one">
            <span class="header">Personal Details</span>
            <form [formGroup]="participantProfileFormPersonalDetails">

                <span class="ln-2">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" formControlName="participantFirstName" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-2">
                    <label for="lastName" >Last Name</label>
                    <input type="text" id="lastName" formControlName="participantLastName" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-3">
                    <label for="NICNo" >NIC No</label>
                    <input type="text" id="NICNo" formControlName="participantNicNo" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-3">
                    <label for="email" >Email Address</label>
                    <input type="text" id="email" formControlName="participantEmail" pInputText placeholder="" readOnly>
                </span>
                <span class="ln-3">
                    <label for="contactNo" >Contact No</label>
                    <input type="text" id="contactNo" formControlName="participantContactNo" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-1">
                    <label for="address" >Address</label>
                    <input type="text" id="address" formControlName="participantAddress" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-1">
                    <label for="about">About (Optional)</label>
                    <textarea type="text" id="about" formControlName="participantAbout" pInputText placeholder="" [readOnly]="!isEditModeOn"></textarea>
                </span>
            </form>
        </div>

        <div class="grid-two">
            <span class="header">Other Details</span>
            <form [formGroup]="participantProfileFormOtherDetails">
                <span class="ln-1" *ngIf="isEditModeOn">
                    <label >Food Preference</label>
                    <p-dropdown [options]="FoodPreference" formControlName="foodPreference" placeholder="Select"
                        optionLabel="name" [showClear]="true"></p-dropdown>
                </span><br>
              <span class="ln-1" *ngIf="!isEditModeOn">
                    <label>Food Preference</label>
                    <input type="text" formControlName="foodPreference" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-1" *ngIf="isEditModeOn">
                    <label>T-Shirt Size</label>
                    <p-dropdown [options]="TshirtSize" formControlName="tShirtSize" placeholder="Select" optionLabel="name"
                        [showClear]="true"></p-dropdown>
                </span><br>
              <span class="ln-1" *ngIf="!isEditModeOn">
                    <label>T-Shirt Size</label>
                    <input type="text" formControlName="tShirtSize" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-1" *ngIf="isEditModeOn">
                    <label >Gender</label>
                    <p-dropdown [options]="Gender" formControlName="gender" placeholder="Select" optionLabel="name"
                        [showClear]="true"></p-dropdown>
                </span><br>
              <span class="ln-1" *ngIf="!isEditModeOn">
                    <label>Gender</label>
                    <input type="text" formControlName="gender" pInputText placeholder="" [readOnly]="!isEditModeOn">
                </span>
                <span class="ln-1">
                    <label >User Group</label>
                        <p-checkbox  formControlName="userGroup" value="Commercial" label="Commercial" [(ngModel)]="selectedValues"></p-checkbox>
                        <p-checkbox  formControlName="userGroup" value="Non-Commercial" label="Non-Commercial" [(ngModel)]="selectedValues"></p-checkbox>
                        <p-checkbox  formControlName="userGroup" value="Community" label="Community" [(ngModel)]="selectedValues"></p-checkbox>
                </span><br>
                <button *ngIf="isEditModeOn" pButton pRipple type="button" class="p-button-outlined p-button-secondary"  label="Update" (click)="createUpdateProfile()"></button>
                <button *ngIf="!isEditModeOn" pButton pRipple type="button" class="p-button-outlined p-button-secondary"  label="Edit" (click)="editProfile()"></button>
            </form>

        </div>

    </div>

</div>
