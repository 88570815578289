<!--
   * This is the Organizer's Dashboard.
   * Please do any kind of implementation regards to the organizer dashboard under the organizer folder.
   * If you have any clarifications feel free to contact me (Lakshan)
 -->

<!--
    ⚠ we need to change this folder name as Organizer. let's change the name after developments are done. until, you can countinue your works as usual.
 -->

<router-outlet></router-outlet>
