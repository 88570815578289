import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AdminEventService} from '../../admin-event.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-approval-organization-details',
  templateUrl: './approval-organization-details.component.html',
  styleUrls: ['./approval-organization-details.component.scss'],
})
export class ApprovalOrganizationDetailsComponent implements OnInit {

  organizationForm = new FormGroup({
    organizationName: new FormControl(),
    regNo: new FormControl(),
    organizationContactNo: new FormControl(),
    organizationEmail: new FormControl(),
    organizationType: new FormControl(),
    address: new FormControl(),
    about: new FormControl()
  });
  representativeForm = new FormGroup({
    name: new FormControl(),
    nic: new FormControl(),
    email: new FormControl(),
    contactNo: new FormControl()
  });
  OrganizationType = [
    {
      name: 'Commercial',
      code: 'COM'
    },
    {
      name: 'Non-Commercial',
      code: 'NONCOM'
    },
    {
      name: 'Society',
      code: 'SOC'
    }
  ];

  constructor(
    private router: Router,
    public adminEventService: AdminEventService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.organizationForm = this.fb.group({
      organizationName: [this.adminEventService.currentEvent.organizerId.organizationName],
      regNo: [this.adminEventService.currentEvent.organizerId.regNo],
      organizationContactNo: [this.adminEventService.currentEvent.organizerId.organizationContactNo],
      organizationEmail: [this.adminEventService.currentEvent.organizerId.organizationEmail],
      organizationType: [this.adminEventService.currentEvent.organizerId.organizationType],
      address: [this.adminEventService.currentEvent.organizerId.organizationAddressLineOne + ' , ' + this.adminEventService.currentEvent.organizerId.organizationAddressLineTwo + ' , ' + this.adminEventService.currentEvent.organizerId.city + '.'],
      about: [this.adminEventService.currentEvent.organizerId.description]
    });
    this.representativeForm = this.fb.group({
      name: [this.adminEventService.currentEvent.organizerId.firstName + ' ' + this.adminEventService.currentEvent.organizerId.lastName],
      nic: [this.adminEventService.currentEvent.organizerId.nicNo],
      email: [this.adminEventService.currentEvent.organizerId.email],
      contactNo: [this.adminEventService.currentEvent.organizerId.contactNo]
    });
  }

  nextPage() {
    this.router.navigate(['d/admin/admin-organizers-approvals/event-details']);
  }
}
