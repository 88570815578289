import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-sign-up-type-participant',
  templateUrl: './sign-up-type-participant.component.html',
  styleUrls: ['./sign-up-type-participant.component.scss']
})
export class SignUpTypeParticipantComponent implements OnInit {

  blockSpecial: RegExp = /^[^<>*!]+$/
  blockSpace: RegExp = /[^\s]/;
  ccRegex: RegExp = /[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/;

  foods = [
    {
      name: 'Vegtarian',
      code: 'VEG'
    },
    {
      name: 'Non-Vegetarian',
      code: 'NONVEG'
    },
    {
      name: 'EGG',
      code: 'EGG'
    }
  ];

  tShirts = [
    {
      name: 'Small',
      code: 'S'
    },
    {
      name: 'Medium',
      code: 'M'
    },
    {
      name: 'Large',
      code: 'L'
    },
    {
      name: 'Extra Large',
      code: 'XL'
    }
  ]
  gender = [
    {
      name: 'Male',
      code: 'M'
    },
    {
      name: 'Female',
      code: 'F'
    },
    {
      name: 'Other',
      code: 'O'
    }
  ]
  constructor(
    private primengConfig: PrimeNGConfig
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }


}

